<template>
<Navigation :showlogo="true" :hidefooter="true">
  <template #body>

    <section class=" w-100 float-left d-table position-relative">
  

      <div class="d-table-cell align-middle content">
         <div class="container" style="padding-top:20px">
            <div class="banner-inner-con text-gray text-center wow bounceInUp" data-wow-duration="1s">
               <h1 class="position-relative text-gray" style="margin-top: 40px;"><span v-if="this.table!=0 && this.table!='nightwise'" style="padding-top: 20px;">Table {{ table }}</span></h1>
            
                <a @click="$router.push('/menu/'+this.club+'/'+this.table)" class="btn-getstarted scrollto position-relative" id="podsjetnik" style="color:orange;font-size: 20px;">
            <i class="fa-solid fa-cart-plus"></i>
            <p style="color:#494B4E">Back to menu</p>
        
    
    </a>
    <div class="generic-button white-btn d-inline-block wow bounceInUp" data-wow-duration="1s" style="" v-if="this.$store.state.isAuthenticated">
                 
                  <a style="color:white;background: #494B4E !important;cursor: pointer;">{{bodovi}} NightWise points <i class="fa fa-moon" style="color:#494B4E;background: white !important;"></i></a>
               </div>
               <div class="generic-button white-btn d-inline-block wow bounceInUp" data-wow-duration="1s" @click="$router.push('/account/'+this.$store.state.klubinfo.ime +'/login/')" v-else>
                 
                 <a style="color:white;background: #494B4E !important;cursor: pointer;">Log in to collect NightWise points <i class="fa fa-moon" style="color:#494B4E;background: white !important;"></i></a>
              </div>
            </div>
         </div>
      </div>
   </section>


   <section class="menu" >
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="page-header wow fadeInDown">
               <br>
               
           
        
              </div>
            </div>
          </div>
          <div class="food-menu wow fadeInUp">


            <br>
            <div class="row">

<p v-show="!this.cart.items.length">Your cart is empty, add something by going back to menu</p>
              <div class="menu-item jelo col-sm-12 col-xs-12 starter" v-for="i in cart.items" :class="i.vrsta"
               >
                <div class="clearfix menu-wrapper">
                  <h4 class="testh4"><i
                      style="cursor:pointer;color:var(--color-primary);font-size: 25px;padding-right: 10px;user-select: none;"
                      class="fa-solid fa-square-plus" @click="addToCart(i.jelo, i.price, i.vrsta, i.id)"></i>
                    <i style="cursor:pointer;color:red;font-size: 25px;user-select: none;" class="fa-solid fa-square-minus"
                      @click="removeFromCart(i.jelo, i.price, i.quantity, i.id)"></i>

                    &nbsp;<span class="broj" :id=i.quantity
                      style="font-size:25px;padding-right:10px;user-select: none;">{{ i.quantity }}X</span> {{ i.jelo }} ({{ i.price }}€ x {{
                        i.quantity }}) &nbsp;&nbsp;
                  </h4>
                  <span class="price">{{ (i.price * i.quantity).toFixed(2) }}€</span>
                  <div class="dotted-bg"></div>
                </div>
              </div>
              <hr>
              <br><br>

      
              <span style="padding-bottom:10px;padding-right: 5px;" class="generic-button red-btn d-inline-block"  >
              <a style="color:white;cursor:pointer" @click="$router.push('/loyalty/'+ this.$store.state.klubinfo.ime)"> Find Rewards <i class="fa fa-coins"></i></a>
              </span> 
              <div class="menu-item jelo col-sm-12 col-xs-12 starter" v-for="i in cart.rewards" style="padding-bottom: 10px;" v-if="$store.state.isAuthenticated">
                <div class="clearfix menu-wrapper">
                  <h4 class="testh4">
                 

                    <i style="cursor:pointer;color:red;font-size: 25px;" class="fa-solid fa-square-minus" @click="removereward(i[2])"></i>
      
                    &nbsp;<span class="broj" style="font-size:25px;padding-right:10px;" v-if="i[4]">{{i[5]}} {{i[3]}}% Off</span>
                    <span class="broj" style="font-size:25px;padding-right:10px;" v-else>{{i[6]}} {{i[3]}}% Off</span>
                     &nbsp;&nbsp;
                  </h4>
                  <span class="price">{{ i[1] }} points &nbsp;  <s>{{i[9]}}€</s>  {{(i[9]*((100-i[3])/100)).toFixed(2)}}€ </span>
                  <div class="dotted-bg"></div>
                </div>
              </div>

              <hr>
              <h4 class="testh4">Total &nbsp; {{ total }}€ &nbsp;&nbsp;</h4>
              <h4 class="testh4" v-if="$store.state.isAuthenticated && totalbodovi">Total points &nbsp; {{ totalbodovi }} &nbsp;&nbsp;</h4>
    
            </div>

    
        

            <span><br>
              <p v-if="this.table!='nightwise'" class="text-center" style="font-weight: bold;color:gray;opacity: 0.9;">

Order directly to your table number: {{ this.table }}<br>

<br>

</p>
<p class="small" v-if="totalbodovi>bodovi && $store.state.isAuthenticated">You don't have enough NightWise points to start the order, please remove some offers.</p>
<div class="text-center">
  <span v-if="showcheckout()">
    <button type="button"  :class="getclass('bar')" @click="choose('bar')">Collect at bar</button>&nbsp;
<button type="button"  :class="getclass('table')" @click="choose('table')">Order to table</button> &nbsp;
  </span>


<div v-if="this.tableorder && this.table == 'nightwise'"><br>
  <input type="number" class="form-control" style="border-radius: 25px" placeholder="Table number" v-model="orderform.table" required>
</div>


  


      <p style="display:none;" id="policytekst">
        Drink Ordering Process
Eligibility: Users must be of legal drinking age in their jurisdiction to order alcoholic beverages.
Order Placement: Drinks can be ordered through the NightWise app. Users must select whether they prefer delivery to their reserved table or collection at the bar.
Payment: Payment must be made at the time of order via our secure online payment system. We accept credit/debit cards and digital wallets.
Delivery and Collection
Table Delivery: Drinks ordered for table delivery will be brought to the specified table number. Please ensure the table number is correct at the time of ordering.
Cancellation and Refund
Order Cancellation: Orders can be cancelled within [Specify Time Period] of placing the order, provided the order has not already been prepared.
Refunds: Refunds for cancelled orders will be processed to the original payment method within [Specify Time Period]. If an order cannot be fulfilled due to unforeseen circumstances, a full refund will be issued.
Responsible Drinking
Discrepancies and Complaints
In case of any discrepancies or complaints regarding the drink order, please contact a staff member immediately or use the in-app support feature.

Liability
NightWise is not responsible for any health issues that may arise from the consumption of beverages ordered through the app.</p>

<div class="form-check form-switch" v-if="this.pokazipayment2">
  <br>
  <input class="form-check-input text-center" type="checkbox" id="flexSwitchCheckDefault" v-model="punoljetan">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel">Morate biti punoljetni kako biste mogli naručiti alkoholna pića, <b style="font-weight: bold;">Potvrđujem da sam punoljetan</b></label>
</div>


<button class="btn btn-info" style="border:none;color:white;background-color:var(--button-color) ; z-index:5;;" id="payement" @click="createDeliverycash('cash')" v-if="(this.cart.items.length || this.cart.rewards.length) && this.table != 'nightwise' && pokazipayment"> Pay by Cash or Card to bartender &nbsp;</button>
              <button class="btn btn-info" style="border:none;color:white;background-color:var(--button-color); margin-top:20px; z-index:5;" id="payement" @click="createDelivery('card')" v-if="showpayment()"> Go to Web Payment &nbsp;</button><br><br>
     

</div>
          
          

            
            </span>
          </div>

        </div>

      </section>
   
    <br>
<div style="height: 80px;"></div>
  </template>
  
</Navigation>
   <!--navbar-->
   <!--banner-->
   


   <!--banner-->

</template>
<style scoped>
@media screen and (min-width: 1000px) {
    .sub-banner-sec{
        padding-top: 80px;
        padding-bottom: 50px
    }
}

</style>
<style>
.icon-circle {
    width: 100px;
    height: 100px;
    background-color: lightgray;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-size: 24px; /* Adjust the size of the icon */
}

.icon-circle .points {
    margin-top: 10px; /* Space between the icon and the number */
    font-size: 20px; /* Adjust the size of the number */
}
</style>

<script>
import axios from 'axios'
import Navigation from '../components/Navigation.vue'
export default {
  name: 'Restaurant',
  components: {
    Navigation
  },

  data(){
    return{
      totalbodovi:0,
      punoljetan:false,
        table:0,
        pokazipayment:false,
        club:"",
        nijestol:true,

        cart: {
        items: [], 
        rewards:[],
      },
      
      orderform:{
            table:"",
            club:"",
            cart:[],
            auth:false,
            token:"",
            collect:true,
            rewards:[],
        },
        bgstylevar:"",
        tableorder:false,
        bar:false,
        bodovi:null,

    }
  },

  beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.token



    if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }
  },

  mounted(){


    $(".containerloading").show()
    if(this.$store.state.isAuthenticated){
      this.getBodovi()
    }
    else{
      $(".containerloading").hide()
    }
   
    
    console.log("klub id u cartu je ", this.$store.state.cart.klubid)
    console.log("klub id je ", this.$store.state.klubid)
    if(!this.$store.state.cart.klubid || (this.$store.state.cart.klubid != this.$store.state.klubid) ){

this.$store.commit('clearCart')
this.$store.commit('setCartKlub', this.$store.state.klubid)


}

    this.cart = this.$store.state.cart
    if(!this.$store.state.cart.rewards){
      this.$store.state.cart.rewards = []
    }
 
    this.orderform.rewards = this.cart.rewards

    this.table = this.$route.params.stol

    if(this.table!='nightwise'){
      this.tableorder = true
      this.orderform.collect = false
      this.orderform.table = this.table
      this.nijestol=false
      this.pokazipayment = true
    }

    this.club = this.$route.params.klub
    this.orderform.club = this.$store.state.klubid
    


    this.orderform.cl




  

  },

  computed: {


total() {
  
  let total = 0
  let bodovi = 0

  for (let i = 0; i < this.cart.items.length; i++) {

      total += this.cart.items[i].price * parseInt(this.cart.items[i].quantity)

  }
  if(this.cart.rewards && this.cart.rewards.length){
    for (let i = 0; i < this.cart.rewards.length; i++) {

total += this.cart.rewards[i][9] * ((100-this.cart.rewards[i][3])/100)
bodovi+=this.cart.rewards[i][1]

}
  }
  else{
    bodovi = 0
  }

  this.totalbodovi = bodovi
  return total.toFixed(2);

},


},




  methods:{
    showpayment(){

      if (this.pokazipayment){
        if(this.cart.items.length || this.cart.rewards.length){
          return true
        }
      }


    },

    showcheckout(){
      if(!this.$store.state.isAuthenticated){
        if(this.nijestol){ return true}
      }
      else{
        if(this.nijestol){
          return true
        }
      }
    },
    async getBodovi() {
    
    await axios
      .get('/loyalty/mojibodovi/')
      .then(response => {

       this.bodovi = response.data.bodovi


      $(".containerloading").hide()
        

      
      })
      .catch(error => {

        axios.defaults.headers.common["Authorization"] = ""
      localStorage.removeItem("token")
      localStorage.removeItem("email")
      $(".containerloading").hide()
          
      })

  },

    async togglepolicy(tekst) {
      $("#" + tekst).toggle();
    },
    async removereward(id) {
      this.$store.commit('removereward', id)
    },

    choose(type){
      if(type=="bar"){
        this.bar = !this.bar
        this.tableorder = false 
        this.orderform.collect = true
        this.pokazipayment = true

       
      }
      else if(type=="table"){
     
        this.tableorder = !this.tableorder
        this.bar = false
        this.orderform.collect = false
        this.pokazipayment = true

      }

    },

    getclass(type){
      
      if(type=="bar"){

        if(this.bar){
          return 'btn btn-danger'
     
        }
        else{
          return 'btn btn-outline-danger'
        }
       
      }
      else if(type=="table"){
     
        if(this.tableorder){
          return 'btn btn-danger'
        }
        else{
          return 'btn btn-outline-danger'
        }
      }
    },

    addToCart(jelo, price, vrsta, id) {
      const item = {
        jelo: jelo,
        price: price,
        quantity: "1",
        vrsta: vrsta,
        id: id,
      }

      this.$store.commit('addToCart', item)
      this.$root.toastnoti("Success!", "Item added to cart", "info")

    },




    removeFromCart(jelo, price, quantity, id) {

      const item = {
        jelo: jelo,
        price: price,
        quantity: quantity,
        id: id,
      }

      this.$store.commit('removeFromCart', item)
      this.$root.toastnoti("Success", "Item removed from cart", "info")

    },


    createDelivery(type) {

    /*
      if(!this.punoljetan){
        this.$root.toastnoti("Error", "Morate biti punoljetni kako biste mogli napraviti narudžbu.", "error")
        return;
      }
      */
      if(!this.bar && !this.tableorder){
        this.$root.toastnoti("Error", "Choose between Collect at bar and Order to table", "error")
        return;
      }
      if(!this.orderform.table && !this.bar){
        this.$root.toastnoti("Error", "Please enter table number", "error")
        return;
      }
      this.orderform.vrstaplacanja = type
      
      this.orderform.auth = this.$store.state.isAuthenticated
      if(this.orderform.auth){
        this.orderform.token = this.$store.state.token
      }
        $(".containerloading").show()
        for (let i = 0; i < this.cart.items.length; i++) {

          this.orderform.cart.push(this.cart.items[i])
       
      }

      if(this.orderform.cart.length == 0 && this.cart.rewards.length == 0){
        this.$root.toastnoti("Error", "Cart is empty", "error")

      }



      axios.post('/stripe/order/', this.orderform)
        .then((res) => {

            this.cart.items = []
            //this.$store.commit('clearCart')
            this.orderform.cart = []
   
            this.$store.state.orders[res.data.id] = [res.data.vrsta, res.data.placanje, res.data.cart]
            var test = localStorage.getItem("authorders")
            //localStorage.setItem("klubinfo", JSON.stringify(res.data))

            localStorage.setItem("orderdata", JSON.stringify(res.data.order))
            const secretresponse = {'clientSecret':res.data.clientSecret}
         
            localStorage.setItem("checkoutresponse", JSON.stringify(secretresponse))
            this.$store.commit('setCheckout', secretresponse)
            this.$store.commit('clearRewards')
            this.$router.push('/stripe/checkout/')


        })

        .catch((error) => {
            $(".containerloading").hide()
            this.cart.items = []
            this.cart.rewards = []
            this.orderform.cart = []
            this.$store.commit('clearCart')
            this.$root.toastnoti("Error", error.response.data, "error")
          this.buttonDisabled = false
        

        })


    },

    createDeliverycash(type) {
    
    /*
      if(!this.punoljetan){
        this.$root.toastnoti("Error", "Morate biti punoljetni kako biste mogli napraviti narudžbu.", "error")
        return;
      }
      */
      if(!this.bar && !this.tableorder){
        this.$root.toastnoti("Error", "Choose between Collect at bar and Order to table", "error")
        return;
      }
      if(!this.orderform.table && !this.bar){
        this.$root.toastnoti("Error", "Please enter table number", "error")
        return;
      }
      this.orderform.vrstaplacanja = type
      
      this.orderform.auth = this.$store.state.isAuthenticated
      if(this.orderform.auth){
        this.orderform.token = this.$store.state.token
      }
        $(".containerloading").show()
        for (let i = 0; i < this.cart.items.length; i++) {

          this.orderform.cart.push(this.cart.items[i])
       
      }

      if(this.orderform.cart.length == 0){
        this.$root.toastnoti("Error", "Cart is empty", "error")

      }



      axios.post('/klub/order/', this.orderform)
        .then((res) => {

            this.cart.items = []
            this.$store.commit('clearCart')
            this.orderform.cart = []

            this.$router.push('/uspjeh/'+this.club+'/'+this.table)
            if(this.orderform.auth){
              this.$root.toastnoti("Success", "You can check order details on your NightWise profile page.", "error")
            }
            this.$store.state.orders[res.data.id] = [res.data.vrsta, res.data.placanje, res.data.cart]
            var test = localStorage.getItem("authorders")
            //localStorage.setItem("klubinfo", JSON.stringify(res.data))

            localStorage.setItem("orderdata", JSON.stringify(res.data))
            
       



        })

        .catch((error) => {
            $(".containerloading").hide()
            this.cart.items = []
            this.$store.commit('clearCart')
            this.orderform.cart = []
          this.$root.toastnoti("Error", "There has been an error, please order directly with bartender until we fix it.", "error")
          this.buttonDisabled = false
        

        })


    },




  addToCart(jelo, price, vrsta, id) {
        const item = {
            jelo: jelo,
            price: price,
            quantity: "1",
            vrsta:vrsta,
            id:id,
        }
       
        this.$store.commit('addToCart', item)
        this.$root.toastnoti("Success!", 'Item added to cart', "info")
           
    },




  },

}
</script>

<style>
.text-gray{
  color:#494B4E

}</style>