<template>
    <div class="w-100 float-left header-con">
      
      <div class="container">
         <nav class="navbar navbar-expand-lg navbar-light p-0">
            <a class="navbar-brand"  v-if="showlogo" @click="$router.push('/'+this.$store.state.klubinfo.ime)"> 
                <img :src="$store.state.klubinfo.logo" style="width:80px;" @load="loaded()" v-if="isNightMode || !$store.state.klubinfo.logobijela ">
               <img :src="$store.state.klubinfo.logobijela" style="width:80px;" @load="loaded()" v-else>
            </a>
            <a class="navbar-brand"  v-if="!showlogo" @click="$router.push('/')"> 
           
               <i class="fa fa-moon" style="color:#494B4E;padding-left: 10px;font-size: 30px;"></i>
               NightWise
            </a>

   
            
            <div class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon" :class="{ 'navbar-toggler-icon nightwisetoggle ': isNightMode }"></span>
               <span class="navbar-toggler-icon" :class="{ 'navbar-toggler-icon nightwisetoggle ': isNightMode }"></span>
               <span class="navbar-toggler-icon" :class="{ 'navbar-toggler-icon nightwisetoggle ': isNightMode }"></span>
         </div>



            <div class="collapse navbar-collapse test2" id="navbarSupportedContent">
               <ul class="navbar-nav ml-auto" >
                  <li class="nav-item">
                     <a class="nav-link p-0" @click="$router.push('/')" v-if="!hidenav" style="cursor: pointer;">
    <i class="fa fa-moon"></i> 
    <span style="padding-left: 5px;">HOME</span>
</a>
                  </li>
           
                  <li class="nav-item active pl-lg-0">
                     <a class="nav-link p-0" :class="{ 'nav-link p-0 nightmode': isNightMode }" :href="'/'+this.$store.state.klubinfo.ime" v-if="!hidenav">EVENTS <span class="sr-only">(current)</span></a>
                  </li>
                 
                  <li class="nav-item">
                     <a class="nav-link p-0" :class="{ 'nav-link p-0 nightmode': isNightMode }" :href="'/about/'+this.$store.state.klubinfo.ime" v-if="!hidenav" >ABOUT</a>
                  </li>

            
          
           
          
                  <div class="navbar-btn d-inline-block">
                <a v-if="!this.$store.state.email" :href="'/account/'+this.$store.state.klubinfo.ime +'/login/'" class="btnatag" :class="{ 'btntag nightmode': isNightMode }">Register &nbsp; <i class="fas fa-angle-right" style="color:#494B4E;"></i></a>
                  <a v-if="this.$store.state.email" :href="'/nightwise/user/'" class="btnatag" :class="{ 'btntag nightmode': isNightMode }">My Account &nbsp;<i class="fa fa-user navicon" style="color:#494B4E;"></i></a>
               </div>

           
               <div class="navbar-btn" v-if="!hidenav">
                  <a :href="'/loyalty/'+ this.$store.state.klubinfo.ime" class="btnatag" :class="{ 'btntag nightmode': isNightMode }">Rewards &nbsp;<i class="fa fa-star navicon" style="color:#494B4E;"></i></a>
               </div>
               <div class="navbar-btn" v-if="!hidenav && this.$store.state.klubinfo.music" >
                  <a :href="'/musicorder/'+ this.$store.state.klubinfo.ime+'/1'" class="btnatag" :class="{ 'btntag nightmode': isNightMode }" >Order Music &nbsp;<i class="fa fa-music navicon" style="color:#494B4E;"></i></a>
               </div>
        
               <div class="navbar-btn d-inline-block" v-if="!hidenav">
                  <a :href="'/menu/'+ this.$store.state.klubinfo.ime+'/nightwise'" class="btnatag" :class="{ 'btntag nightmode': isNightMode }">Order Drink&nbsp; <i class="fa fa-martini-glass navicon" style="color:#494B4E;"></i></a>
               </div>

        


         
             
               </ul>
               
           

         
           
                  
            </div>
         </nav>
      </div>

   </div>

<slot name="body" ></slot>



<section class="footer-main-sec w-100 float-left padding-top position-relative mobilenav" v-if="!hidefooter">
      <div class="container">
         
         <!--form-sec-->
         <div class="form-sec d-flex align-items-center" style="position: relative;">
            <h3 class="mb-0">Subscribe To <br>
               Our Newsletter</h3>
               <div class="alert alert-warning" role="alert" v-show="subscribe">
Success! Thank you for subscribing to our nesletter
</div>
            <form @submit.prevent="makeNewsletter()">
            <input type="email" placeholder="Enter Email Address" v-model="this.email">
            <div class="form-btn red-btn d-inline-block">
               <button type="submit" >SUBSCRIBE <i class="fas fa-angle-right"></i></button>
            </div>
         </form>

         </div>
         <div class="footer-inner-sec" >
               <div class="container" style="padding-top: 100px;">
         
         <!--form-sec-->
         <div class="form-sec d-flex align-items-center" style="position:relative !important;">
            <h3 class="mb-0">NightWise</h3>
          
           <p>
      
         Rovinj, 52210, Croatia<br> Contact: +385 994351095<br> OIB: 54836231721<br>IBAN: LT303250092609461757</p>

         </div>
      
         <!--form-sec-->

      </div>
     <div class="footer-social-sec text-center">
        <a >
           <figure>
               <img :src="$store.state.klubinfo.logo" style="width:120px;" v-if="isNightMode || !$store.state.klubinfo.logobijela ">
              <img :src="$store.state.klubinfo.logobijela" style="width:120px;" v-else>
           </figure>
        </a>
        <ul class="list-unstyled p-0">
         
           <li class="d-inline-block"><a class="d-inline-block" :href="$store.state.klubinfo.facebook"><i class="fab fa-facebook-f"></i></a></li>
           <li class="d-inline-block"><a class="d-inline-block" :href="$store.state.klubinfo.instagram"><i class="fab fa-instagram"></i></a></li>
           <li class="d-inline-block"><a class="d-inline-block" :href="$store.state.klubinfo.twitter"><i class="fab fa-twitter"></i></a></li>
      

        </ul>
       
     </div>
  </div>
         <!--form-sec-->

      </div>

   </section>

</template>

<style>
@media screen and (min-width: 768px) {
   .nightmode{
   color:black !important;
}
}

@media only screen and (max-width: 991px){
.navbar-collapse-main {
    background: #494B4E;
    text-align: center;
    border-radius: 5px;
    padding: 0 20px;
}
}</style>
<script>

import router from '@/router';
import axios from 'axios';

export default {
  name: 'Navigation',
  components: {



  },
  props: {
  hidefooter:true,
  showlogo:true,
  bijelilogo:false

  },

  data(){
    return{
        email:"",
        subscribe:false,
        hidenav:false,
    }
  },
  computed: {
    isNightMode() {

      return this.$route.name === 'account' || this.$route.name == 'music' || this.$route.name == 'loyalty' || this.$route.name == 'cart';
    }
  },


  mounted(){

   console.log(this.$store.state.klubinfo)
   if(this.$route.params.klub == 'nightwise'){
      this.hidenav = true

   }
   else if(this.$route.params.klub){
      this.$root.getKlub(this.$route.params.klub)
   }
   else{
      this.$root.getKlub(this.$store.state.klubinfo.ime)
   }
   
    



  },
  methods:{
   async togglepolicy(tekst) {
      $("#" + tekst).toggle();
    },


   loaded(){
      return;
      this.$store.state.loadcount+=1
      console.log("COUNT JE ", this.$store.state.loadcount)
      if(this.$store.state.loadcount==2){
         setTimeout(() => {
            $(".containerloading").hide()
      }, 500); // 1000 milliseconds = 1 second
      
      }
      },
      


 async makeNewsletter() {
    
   

   await axios
     .get('/newsletter/'+this.email+'/'+this.$store.state.klubid)
     .then(res => {


      this.$root.toastnoti("Success!", 'Thanks for subscribing!', "uspjeh")
      
  
   
       
   
   
     })
     .catch(error => {
   
       console.log(error)
   
     })
   
   },
    
}
}
</script>


<style>
.navicon{
   margin-top: 5px;

}
.btnatag{
   text-align: center;
}

@media screen and (min-width: 991px) {

    
}

@media only screen and (max-width: 600px) {
    .mobilenav {
       margin-top: 30%;
    }


}


</style>

