<template>
    <Navigation :showlogo="true">
      <template #body>
      
  <div class="content" >
  

    <div class="container" style="padding-top: 100px;">

        

      <div class="row align-items-stretch no-gutters contact-wrap">
        <div class="col-md-12">

            <div @click="toggle('profil')" style="cursor: pointer;">
        <i :class="{ 'fa fa-user': !profil, 'fa fa-arrow-up': profil }"></i> &nbsp;
    <span>Profile info</span>
    </div>
          <div class="form " v-show="profil">
    
            <span >
 

 </span><br>

            <form v-on:submit.prevent="submitPost" class="mb-5">

              <div class="mb-5">
                <label for="message" class="col-form-label">Full name</label>
                <input
                  class="form-control"
                  id="email"
                  v-model="this.profildata.ime"
         
                  style="color: gray"
                  
            
                />
              </div>
              <div class="mb-5">
                <label for="message" class="col-form-label">Email</label>
                <input
                  class="form-control"
                  id="email"

                  v-model="profildata.email"
                  style="color: gray"
                  
            
                />
                </div>
              
                <div class="mb-5">
                <label for="message" class="col-form-label">Phone Number</label><br>
   
                <vue-tel-input
                v-bind="bindprops"
                  :value="profildata.telefon"
                  @input="onInput"
                  mode="international"
                ></vue-tel-input>
                </div>
              
                <div class="mb-5" v-if="verifytel">
                <label for="message" class="col-form-label">4 Digit Code</label>
                <input
     
                type="text" inputmode="numeric" pattern="[0-9]*"
                  class="form-control"
                  id="email"
                  style="color: gray; border-color: var(--button-color);width: 40% !important;"
                  v-model="profildata.kod"
                  
            
                />
                </div>
              <button class="btn btn-success" style="background-color: var(--button-color);margin-right: 5px;border:none;"  @click="editprofil()" v-if="verifytel">Verify</button> 

              <button class="btn btn-success" style="background-color: rgb(73, 75, 78) !important;border: none" @click="editprofil()" v-if="!verifytel">Save</button>
                    
              <div class="row">
                <div class="col-md-12 form-group">
                 <button class="btn" style="background-color: ;"></button>
                </div>
              </div>
            </form>
          </div>
          <br>

          <div @click="toggle('tickets')" style="cursor: pointer;">
        <i :class="{ 'fa fa-ticket': !tickets, 'fa fa-arrow-up': tickets }"></i> &nbsp;
    <span>Tickets</span> 
    </div>

    <div v-if="tickets">
      <p style="font-weight: 600;" v-if="ticketslist && !ticketslist.length"><br>You don't have any tickets</p>
        <br>
        <div class="card mb-3"  v-for="i in ticketslist">
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a class="d-flex" style="text-decoration: none;">
                                                  <span class="stepnum" style="position: absolute;">{{ new Date(i.eventticket__event__datum).toLocaleDateString('uk', { day: 'numeric', month: 'numeric' }).replace(/\//g, '.') }}</span>
                                                    <div class="flex-fill ms-3">
                                                      
                                                        <h6 class="d-flex justify-content-between mb-0"><span style="color:gray;font-weight: bold;margin-left: 70px;">{{ i.eventticket__event__klub__ime }} Club</span></h6><br>
                                                    
                                                        <span class="text-muted" style="font-size: 15px;" >Description: <b style="font-weight:bold !important;">{{ i.eventticket__opis }}</b></span><br>
                                                      
                                                        <span class="text-muted" style="font-size: 15px;" >Event: <b style="font-weight:bold !important;">{{i.eventticket__event__naslov}}</b><br></span>
                                                        <p style="font-weight: bold;color:var(--button-color)" v-if="!i.aktivno">Invalid, already used</p>
                                                        <p style="font-weight: bold;color:var(--button-color)" v-if="i.aktivno">Valid</p>
                                             
                                                    </div>
                                                </a>
                                          
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                          
                                                <div class="card-hover-show">
                                                    <a class="btn btn-sm btn-dark border lift themebg" v-if="i.aktivno" @click="otvoriticket(i.kod)">Qrcode</a>&nbsp
                                                    <a class="btn btn-sm btn-dark border lift themebg" @click="$router.push('/details/'+i.eventticket__event__klub__ime+'/'+i.eventticket__event__id)">Event detalji</a>
                                
                                                </div>
                                            </div>
                                        </div>
    </div>

                        <br>
                        
          <div @click="toggle('tables')" style="cursor: pointer;">
        <i :class="{ 'fa fa-table': !tables, 'fa fa-arrow-up': tables }"></i> &nbsp;
    <span>Tables</span> 
    </div>

    <div v-if="tables">
<p style="font-weight: 600;" v-if="stolovilist && !stolovilist.length"><br>You don't have any table reservations</p>
           
  
        <div class="card mb-3" v-for="i in stolovilist">
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a class="d-flex" style="text-decoration: none;">
                                                    <span class="stepnum">{{ i.stol }}</span>
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <h6 class="d-flex justify-content-between mb-0"><span style="color:gray;font-weight: bold;">{{ i.klub__ime }}</span></h6><br>
                                                  
                                                        <span class="text-muted"  style="font-size: 15px;" v-if="i.deposit!=0">Deposit:  <b style="font-weight:bold !important;"> {{ i.deposit }}€</b>
                                              
                                                        </span>
                                                        <span class="text-muted"  style="font-size: 15px;" v-if="i.deposit==0">  <b style="font-weight:bold !important;"> Free reservation</b>
                                              
                                            </span>
                                            <br>
                                                   
                                                        <span class="text-muted" style="font-size: 15px;" >Datum: <b style="font-weight:bold !important;">{{ i.event__datum }}</b><br></span>
                                                    </div>
                                                </a>
                                          
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                          
                                                <div class="card-hover-show">
                                               
                                                    <a class="btn btn-sm btn-dark border lift themebg" @click="$router.push('/details/'+i.event__klub__ime+'/'+i.event__id)">Event detalji</a>&nbsp
                                            
                                                </div>
                                            </div>
                                        </div>
    </div>
<br>
<div @click="toggle('orders')" style="cursor: pointer;">
        <i :class="{ 'fa fa-martini-glass': !orders, 'fa fa-arrow-up': orders }"></i> &nbsp;
    <span>Orders</span> 
    </div>
    <div v-if="orders">
      <p style="font-weight: 600;" v-if="narudzbelist && !narudzbelist.length"><br>You don't have any drink orders</p>
        <br>
        <div class="card mb-3" v-for="i in narudzbelist">





                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a class="d-flex" style="text-decoration: none;">
                                                    <span class="stepnum" style="position: absolute;">{{ i[1] }}</span>
                                         
                                                    <div class="flex-fill ms-3">
                                                        <h6 class="d-flex justify-content-between mb-0"><span style="color:gray;font-weight: bold;padding-top: 10px;margin-left: 70px;">{{i[0]}} Club</span></h6><br>
                                                       
                                                        <span class="text-muted" style="font-size: 15px;" v-for="j in i[3]">{{ j[1] }}x {{j[0]}}  <b style="font-weight:bold !important;">{{j[2]}}€</b><br></span>
                                                      <hr v-if="i[5] && i[5].length">
                                                      <span class="text-muted" style="font-size: 15px;" v-for="j in i[5]">{{ j[2] }} {{j[0]}}% <b style="font-weight: bold;">{{ j[3] }}€ + </b> <b style="font-weight:bold !important;">{{j[1]}}points</b><br></span>
                                                        <hr>
                                                        <span class="text-muted" style="font-size: 15px;" >Total <b style="font-weight:bold !important;">{{i[6]}}€ </b><br></span>
                                                        <span class="text-muted" style="font-size: 15px;" >Total Points <b style="font-weight:bold !important;">{{i[7]}} </b><br></span>
                                                        <span class="text-muted" style="font-size: 15px;" >Payment made by  <b style="font-weight:bold !important;">{{i[4]}}</b><br></span>
                                                    </div>
                                                </a>
                                          
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                          
                                                <div class="card-hover-show">
                                         
                                
                                                </div>
                                            </div>
                                        </div>
    </div>
<br>
    <div @click="toggle('music')" style="cursor: pointer;">
        <i :class="{ 'fa fa-music': !music, 'fa fa-arrow-up': music }"></i> &nbsp;
    <span>Music Orders</span> 
    </div>
    <div v-if="music">
      <p style="font-weight: 600;" v-if="musiclist && !musiclist.length"><br>You don't have any music orders</p>
        <br>
        <div class="card mb-3" v-for="i in musiclist">
                                            <div class="card-body d-sm-flex justify-content-between">
                                                <a class="d-flex" style="text-decoration: none;">
                                                  <span class="stepnum">{{ new Date(i.vrijemeizrade).toLocaleDateString('uk', { day: 'numeric', month: 'numeric' }).replace(/\//g, '.') }}</span>
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <h6 class="d-flex justify-content-between mb-0"><span style="color:gray;font-weight: bold;">{{i.klub__ime}} Club</span></h6><br>
                                                       
                                                        <span class="text-muted" style="font-size: 15px;"><b style="font-weight:bold !important;">Song: {{ i.pjesma }}</b></span><br>
                                                        <span class="text-muted" style="font-size: 15px;color:green" v-if="!i.odobreno"><b style="font-weight:bold !important;color:pink">Waiting for DJ approval</b></span>
                                                        <span class="text-muted" style="font-size: 15px;color:green" v-if="i.odobreno && !i.placeno"><b style="font-weight:bold !important;color:green">Approved by DJ, waiting for payment</b></span>
                                                        <span class="text-muted" style="font-size: 15px;color:green" v-if="i.odobreno && i.placeno && !i.zavrseno"><b style="font-weight:bold !important;color:green">All set, DJ will play the song.</b></span>
                                                        <span class="text-muted" style="font-size: 15px;color:green" v-if="i.zavrseno" ><b style="font-weight:bold !important;color:green">Song played</b></span>
                                                     
                                                        <hr>
                                                        <span class="text-muted" style="font-size: 15px;" >Total <b style="font-weight:bold !important;">{{i.cijena}}€</b><br></span>
                                                       
                                                    </div>
                                                </a>
                                          
                                            </div>
                                            <div class="card-footer justify-content-between d-flex align-items-center">
                                          
                                                <div class="card-hover-show">
                                         
                                
                                                </div>
                                            </div>
                                        </div>
    </div>



    <br><br>

                        <i class="fa fa-sign-out circle-icon" style="color: white;font-size: 15px;cursor: pointer;" @click="logout()"></i>
                        <p style="padding-top: 5px;">Log out</p>
                     

        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="ticketmodal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel"></h4>
            <i class="fa fa-circle-xmark" style="color:#ed1650;font-size: 30px;cursor: pointer;"  @click="this.zatvoriform('ticketmodal')" ></i>
  
          </div>

          <div class="modal-body text-center">
            <form style="padding:10px">		

		
				<p style="color:gray;" class="text-center">Show this qr code to staff upon entrance</p><br>
                <qrcode-vue :value="ticketqr" :size="size" level="H" class="d-flex justify-content-center"/><br>
                
               
         <br>
        
          </form>
          </div>
          <div class="modal-footer d-flex justify-content-center">


  
          </div>
        </div>
      </div>
    </div>
    
      </template>
      
    </Navigation>

    
    </template>
    
    <style scoped>
.alter1-header {
    position: relative;
    background-image: linear-gradient(160deg, white 0, #5b6269 70%, #323436 110%);

    height: 50px;
}

</style>
    <script>
    import { VueTelInput } from 'vue3-tel-input'
    import 'vue3-tel-input/dist/vue3-tel-input.css'
    import QrcodeVue from 'qrcode.vue'
    import axios from 'axios'
    import Navigation from '../components/MainNavigation.vue'
    export default {
      name: 'Account',
      components: {
        Navigation,
        QrcodeVue,
        VueTelInput,
      },
    
      data(){
        return{
          size: 200,
          ticketqr:null,
          profildata:{
            ime:"",
            email:"",
            telefon:"",    
            kod:"",
            verify:false,
          },
          loyaltylist:[],
          ticketslist:[],
          stolovilist:[],
          narudzbelist:[],
          musiclist:[],
          verifytel:false,
          verify:false,
       
            profil:true,
            tickets:false,
            loyalty:false,
            tables:false,
            orders:false,
            music:false,
     
            bindprops: {
        preferredCountries: ["DE", "AT", "SI", "DK", "NL", "GB", "FR", "ES", "RS"],
      },
        }
      },
    

      mounted(){
   
        $(".containerloading").show()
        this.getProfil()

    
      },
    
    
    
      methods:{
        onInput(phone, phoneObject, input) {
      if (phoneObject?.formatted) {
        console.log("ets")
        if(this.profildata.telefon != phoneObject.formatted) {
        
          this.verify = true
        }
        this.profildata.telefon = phoneObject.formatted
      }
      },

        editstol(id, brojosoba){
          axios.post('/guest/editstol/', {'id':id, 'brojosoba':brojosoba})
        .then((res) => {

          this.$root.toastnoti("Success!", "Reservation is saved", "uspjeh")


        })

        .catch((error) => {
          this.$root.toastnoti("Error", "While saving data", "greska")
        

        })

        },
        editprofil(){
    
          this.profildata.telefon = $('.vti__input').val()
          this.profildata.verify = this.verify
          console.log(this.profildata)
          axios.post('/guest/editprofil/', this.profildata)
        .then((res) => {

          if(this.profildata.verify && this.profildata.kod == ""){
            this.$root.toastnoti("Info", "Please enter the 4 digit number you can find in sms we sent you,", "uspjeh")
            this.verifytel = true
          }
          else{
            this.profildata.kod = ""
            this.verifytel = false
            this.verify = false
            this.$root.toastnoti("Success!", "Profile is saved", "uspjeh")

          }
        

        })

        .catch((error) => {
         
          this.$root.toastnoti("Error", error.response.data, "greska")

        })

        },
        totalprice(items){
         let total = 0
          for(var i=0; i<items.length; i++){
            total+=items[i][1]*items[i][2]
          }
          return total


        },
        zatvoriform(modalname){

$('#' + modalname + ' ' +  '.modal-content').removeClass('animate-bottom').addClass('animate-down');
 $("#"+modalname).modal('hide');   



},
        otvoriticket(qr){
          console.log(qr)
          this.ticketqr = qr
          $("#ticketmodal").modal("show")

        },

        async getProfil() {
        
        await axios
          .get('/guest/profil/')
          .then(res => {
            console.log(res.data)
            this.profildata.ime = res.data.ime
            this.profildata.telefon = res.data.telefon
            this.profildata.email = res.data.email
            this.loyaltylist = res.data.loyalty
            this.ticketslist = res.data.tickets
            this.stolovilist = res.data.stolovi
            this.narudzbelist = res.data.narudzbe
            this.musiclist = res.data.music
     
            console.log(res.data)
  
          $(".containerloading").hide()
            
  
          
          })
          .catch(error => {
            if(error.response.status == 401){
              
              if(this.$store.state.klubinfo.ime){
          this.$router.push('/account/' + this.$store.state.klubinfo.ime + '/login/')
          }
          else{
              this.$router.push('/account/nightwise/login/')
          }
          }
          })
  
      },

        async logout() {


await axios
      .post("/klub/logout/guest/", {token : localStorage.getItem('token')})
      .then(response => {
        
        this.$store.state.email = ""
      this.$root.toastnoti("Success!", "You are logged out", "greska")
      })
      .catch(error => {
        this.$root.toastnoti("Success!", "You are logged out", "greska")

      })
      axios.defaults.headers.common["Authorization"] = ""
      localStorage.removeItem("token")
      localStorage.removeItem("email")


      this.$router.push('/account/'+this.$store.state.klubinfo.ime+"/login/");
      


  
},

        toggle(section){
            if(section == "profil"){
                this.profil = !this.profil
            }
            if(section == "tickets"){
                this.tickets = !this.tickets
            }
            if(section == "loyalty"){
                this.loyalty = !this.loyalty
            }
            if(section == "tables"){
                this.tables = !this.tables
            }
            if(section == "orders"){
                this.orders = !this.orders
            }
            if(section == "music"){
                this.music = !this.music
            }
        }
    
       
    
    
    
    
      },
    
    }
    </script>

<style>
canvas {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 800px;
}

    .circle-icon {
            display: inline-block;
            width: 50px; /* Set the width and height to make it a circle */
            height: 50px;
            line-height: 50px; /* Center the icon vertically */
            text-align: center; /* Center the icon horizontally */
            border-radius: 50%; /* Make it a circle by setting border-radius to 50% */
            background-color: var(--button-color); /* Add a background color if needed */
            color: #fff; /* Set the icon color */
        }

@media screen and (max-width: 767px){
    div.dataTables_wrapper div.dataTables_length, div.dataTables_wrapper div.dataTables_filter, div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
    text-align: left !important;
    padding-bottom: 20px;
}

}

#myProjectTable_length{
    display: none;
}

@media only screen and (max-width: 767px){
    #myProjectTable_length, #myProjectTable_filter, #myProjectTable_info, #myTransaction_length, #myTransaction_filter, #myTransaction_info, #patient-table_length, #patient-table_filter, #patient-table_info {
    display: block;
}
}



  @import '../assets/plugin/datatables/responsive.dataTables.min.css';
</style>

<style>
@media only screen and (max-width: 991px) {
   .nightwisetoggle {
      background: rgb(73, 75, 78) !important;
}

}
</style>

    
    <style scoped>
.order-card {
    color: #fff;
}

.bg-c-blue {
    background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
    background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
    background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
    background: linear-gradient(45deg,#FF5370,#ff869a);
}
.bg-c-gray {
    background: linear-gradient(45deg,#d3d3d3,#d3d3d3);
}



.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    box-shadow: 0 1px 2.94px 0.06px rgba(4,26,55,0.16);
    border: none;
    margin-bottom: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card .card-block {
    padding: 25px;
}

.order-card i {
    font-size: 26px;
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}


.mb-5 {
    margin-bottom: 1rem!important;
}

    .content{
        color:gray
    }
.nightwisebtn a:hover{
   color: white;
}
.nightwisebtn a:hover i{
   background: white;
}


.form-check-input:checked {
   background-color: var(--footercolor);
   border-color: var(--footercolor);
}

</style>



<style scoped>

.project {  

    max-width: 100%;  

}  


.submitbtn{
    font-weight: 400;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: var(--color-white);
    background: var(--color-primary);
    font-family: var(--font-secondary);

}

.stepnum{
max-height: 1.6em;
max-width: 10em;
padding-left: 10px;
padding-right: 10px;
background: #ed1650;
border-radius: 0.8em;
-moz-border-radius: 0.8em;
-webkit-border-radius: 0.8em;
color: #ffffff;
display: inline-block;
font-weight: bold;
line-height: 1.6em;
margin-right: 20px;
text-align: center;

font-size: 20px !important;

}

.themebg{
    /*background: #494B4E !important;*/
    background-color:  gray;
    border: none;
}

</style>

