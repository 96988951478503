<template>

    <MainNavigation>
       <template #body>
         <section class="banner-main-sec w-100 float-left d-table position-relative banner-main-sec2" :style="{'background' : `url(../assets/images/bg1.jpg) no-repeat center bottom`, 'background-size':'cover'}">
         <div class="overlay overlay2" ></div>
             <div class="d-table-cell align-middle content">
         <div class="container text-center">
            <div class="banner-inner-sec text-white d-inline-block">
    
               <span class="wow bounceInUp d-inline-block" data-wow-duration="1s"></span>
               <h1 class="position-relative wow bounceInUp" data-wow-duration="1s" v-html="animatedText"></h1>
               <p class="wow bounceInUp" data-wow-duration="1s" id="typewriter">
                NightWise
             </p>
             
            </div>
         </div>

      </div>

      
   </section>
<br>
<section class="conference-sec w-100 float-left padding-top padding-bottom" style="padding-top: 50px;padding-bottom: 0px;">
      <div class="container">
         <div class="row">
            <div class="col-lg-12">
               <div class="confernce-text-sec">
                  <h2>Find <i class="fa-solid fa-magnifying-glass" style="color:orange"></i></h2>
            <p>One NightWise account for all nightclubs.</p>
            <input type="text" class="form-control wow bounceInUp" style="border-radius: 10px;" placeholder="City, Club name ..." data-wow-duration="1s" v-model="filterval" v-on:input="filterclubs()"><br>
               </div>


               
            </div>
            <div class="col-lg-6" v-for="(item, index) in klubovi" :key="index"  @click="this.$router.push('/'+item[0])">
               <div class="">
                  <ul class="mb-0 list-unstyled">
                     <li  >
                        
                        <div class="static-con text-center">
                        
                           <figure>
                              <img :src="item[2]" alt="static-img" style="width:30%;height: auto;">
                           </figure>
                           <div class="value">{{item[0]}}</div>
                           <span>{{item[1]}}</span>
                           
                        </div>
   
                      
                     </li>
                    
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </section>
   
           <div class="container text-center" style="padding-top: 0px;">

            <section class="speakers-sec speakers-main-sec w-100 float-left padding-top padding-bottom position-relative">
      <div class="container">
      

       
     

         <br>
         <div class="generic-title text-center wow bounceInUp" data-wow-duration="2s">
            <h2>Tredning Events <i class="fa fa-fire" style="color:orange"></i></h2>
            <p>Click on the event to view Club App</p>
         </div>
         <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" v-for="event in eventi">
                <a @click="$router.push('/details/'+event[0]+'/'+event[3])">
   <div class="speaker-con position-relative w-100 float-left wow bounceInUp" data-wow-duration="0.8s">
      <figure class="mb-0">
         <img :src="event[5]" alt="petey-img" v-if="event[5]" class="img-fluid eventslika">
         <img src="../assets/images/bob-img.jpg" alt="petey-img" v-else>
         <!-- Add overlay div -->
         <div class="image-overlay"></div>
      </figure>
      <div class="speaker-detail w-100 d-flex align-items-center justify-content-between">
         <div class="speaker-status text-white" style="text-align: left;">
            <h6> <b style="font-weight: bold;color:orange">{{ event[0] }} </b> </h6>
            <div class="truncate-text">{{event[1]}}</div>
         </div>
         <div class="speaker-btn d-inline-block">
            <i class="fas fa-angle-right"></i>
         </div>
      </div>
   </div>
</a>
            </div>
   
           
          
         </div>
      </div>
   </section>
    

           </div>
    

           <div style="height: 100px;"></div>
       </template>
    </MainNavigation>
  
        </template>
        
        
        <script>
        import MainNavigation from '../components/MainNavigation.vue'
        import axios from 'axios'
        
        export default {
          name: 'HomeView',
          components: {
            MainNavigation
          },
          data(){
           return{
            dataText:["Explore...", "Embrace...", "Reveal...", "Engage...", 'Enjoy...', 'Connect'],
            currentIndex: 0,
            animatedText: "",
            eventi:[],
            klubovi:[],
            klubovi2:[],
            filterval:"",
      
           }
           
          },
          mounted(){
            $(".containerloading").show()
            this.getData()
  
    

            setTimeout(() => {
                this.startTextAnimation(0)
      }, 500); // 1000 milliseconds = 1 second
      
  
          },
        
          methods : {
            filterclubs(){
               var inputtext = this.filterval
               if(inputtext.trim() == ""){
                  this.klubovi = this.klubovi2
               }
               else{
                  const searchRegex = new RegExp(this.filterval, 'i');
            

            this.klubovi = this.klubovi2.filter(item => {
              return (
                searchRegex.test(item[0]) ||
                searchRegex.test(item[1])
            
              );
            });
               }
            

            },

            async getData() {
   
      

   await axios
     .get('/guest/nightwisehome/')
     .then(res => {

        this.eventi = res.data.events
        this.klubovi = res.data.klubovi
        this.klubovi2 = res.data.klubovi
        console.log(res.data)
       
        $(".containerloading").hide()
   
     })
     .catch(error => {

   
     })
   
   },

        
            typeWriter(text, i, fnCallback) {
      if (i < text.length) {
        this.animatedText = text.substring(0, i + 1);
        setTimeout(() => {
          this.typeWriter(text, i + 1, fnCallback);
        }, 100);
      } else if (typeof fnCallback === "function") {
        setTimeout(fnCallback, 700);
      }
    },
    startTextAnimation(i) {
      if (typeof this.dataText[i] === "undefined") {
        setTimeout(() => {
          this.startTextAnimation(0);
        }, 20000);
      } else if (i < this.dataText[i].length) {
        this.typeWriter(this.dataText[i], 0, () => {
          this.startTextAnimation(i + 1);
        });
      }
    },
        }
    }
        </script>
        

        <style>


.truncate-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px; /* Adjust the max-width as needed */
    }

    .speaker-con {
      position: relative;
   }
   .image-overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 90%; /* Adjust the height to cover the bottom half */
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1)); /* Adjust the last value (0.5) for the darkness level */
   }



@media only screen and (max-width: 991px){
   .banner-main-sec2{
   height:250px;
   border-radius: 0 0 0 200px;
}
}

.likeclub{
   color:gray;font-size: 20px;float:right;
}
.likeclub:hover{
   color:yellow;
   font-size: 50px;
}
</style>
