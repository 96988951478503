<template>

	<div id="notfound" >
		<div class="notfound" >

            <img src="../../assets/images/yes.png" alt="" style="width:35%;padding-bottom: 20px;">
            <p v-if="order.bodovi" style="font-weight: bold;font-size: 20px;color:var(--button-color);">{{ order.bodovi }} points</p>
    

        <span >


            <p style="font-size:20px; color:gray;">Thank you for your purchase</p><br>

            <div style="padding-bottom: 15px;">
              <button v-if="this.$store.state.isAuthenticated" class="btn btn-light" style="border:none;border-radius: 25px;" @click="$router.push('/nightwise/user/')"><i class="fa fa-ticket" style="color:black;"> &nbsp;</i> More Details at Profile</button>
    
            </div>
            <p>We also sent you an email</p>
            <br>
       
      
            <p style="color:gray">Go back to nightwise.events</p>

            <button  class="btn btn-light" style="border:none;border-radius: 25px;" @click="$router.push('/'+this.$store.state.klubinfo.ime)"><i class="fa fa-arrow-left" style="color:black;"></i></button>

     

        </span>
		
        <br>
        

      
    
		</div>
	</div>


</template>


<script>
import axios from 'axios'

export default {
  name: 'Uspjeh',
  data(){
    return{
    form:{
        sessionid:"",
        email:"",
        
    },
    order:"",
      
      
    }
  },

  computed: {




  },

  mounted(){
    this.form.sessionid = this.$route.query.session_id;
    this.form.email = this.$route.query.email;
    this.order = JSON.parse(localStorage.getItem("orderdata"))
    if (this.order.bodovi>0){
      this.order.bodovi = "+"+this.order.bodovi
    }


    this.confirmticket()
    $(".containerloading").hide()
    


  },
  
  methods:{
    confirmticket(){
        console.log(this.form)
        axios.post('/stripe/ticketconfirm/', this.form)
    .then((res) => {

   

    console.log(res)
    this.$root.toastnoti("Success!", 'Ticket is sent to your email, you can also check it out at your profile page', "uspjeh")
      
  

            
      })
    .catch((error) => {
        console.log(error)
        //this.$root.toastnoti("Error", 'There was an error, please contact us. Info in footer', "greska")

    })
  
    

  }
  

  
  
}
}
</script>
<style scoped>

#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
}

.notfound .notfound-404 h1>span {
  color: #00b7ff;
}

.notfound h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

.notfound .notfound-search {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: auto;
}

.notfound .notfound-search>input {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 50px;
  padding: 3px 65px 3px 30px;
  color: #151515;
  font-size: 16px;
  background: transparent;
  border: 2px solid #c5c5c5;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search>input:focus {
  border-color: #00b7ff;
}

.notfound .notfound-search>button {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.notfound .notfound-search>button>span {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: -3px;
}


@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}


</style>