<template>

<Navigation>
   <template #body>
      <section class="sub-banner-sec w-100 float-left d-table position-relative">
          <div class="d-table-cell align-middle">
             <div class="container">
                <div class="banner-inner-con text-white text-center">
                   <h1 class="position-relative"></h1>
              
                    <div class="navbar-btn nightwisebtn d-inline-block" v-if="type=='register'">
                  <a  :href="'/account/' + this.$route.params.klub + '/login/'"  style="background:#494B4E ;">Log in <i style="color:#494B4E;" class="fas fa-angle-right"></i></a>
               </div>

               <div class="navbar-btn nightwisebtn d-inline-block" v-if="type=='login'">
                  <a  :href="'/account/'+ this.$route.params.klub +'/register/'" style="background:#494B4E ;">Register <i class="fas fa-angle-right" style="color:#494B4E;"></i></a>
               </div>
                </div>
             </div>
          </div>
       </section>
       <!--banner-->
       <!--conference-sec-->
       <section class="contact-main-sec w-100 float-left padding-top padding-bottom" v-if="type=='register'">
      <div class="container">
         <div class="generic-title text-center wow bounceInUp" data-wow-duration="1s">
            <h2>Create Account</h2>
            <p>You can use same account for all NightWise Club pages <br></p>
         </div>
         <div class="row">
  
            <div class="col-lg-8">
               <div class="contact-form">
                  <form @submit.prevent="submitRegister">
                     <ul class="list-unstyled mb-0">
                        <li class="float-left"><input class="w-100" placeholder="Full Name" type="text" name="name" id="name" v-model="registerform.name"></li>
                        <li class="float-left"><input class="w-100" type="email" placeholder="Your Email" name="email" id="email"  v-model="registerform.email"></li>
                        
                        <li class="float-left">    
                            <vue-tel-input
                v-bind="bindprops"
            
                  mode="international"
                ></vue-tel-input>

                        </li>



                        <li class="float-left"><input class="w-100" type="password" placeholder="Password" name="password" id="password"  v-model="registerform.password"></li>
                        <div class="form-check form-switch">
  <input class="form-check-input text-center" type="checkbox" id="flexSwitchCheckDefault" v-model="registerform.obavijesti" v-if="this.loginform.klubid">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel" v-if="this.loginform.klubid">Želim primati email obavijesti o eventima od {{ this.$store.state.klubinfo.ime }}</label>
</div><br>

                 <li class="float-left"><input class="w-100" type="text" inputmode="numeric" pattern="[0-9]*" placeholder="4 Digit code"  style="width: 40% !important;" v-model="registerform.kod" v-if="verify"><br></li>
                        <li class="mb-0 w-100 d-inline-block form-btn red-btn" v-if="!verify">
                           <button style="background-color: #494B4E;;">Register<i class="fas fa-angle-right" style="color:#494B4E;"></i></button>
                        </li>
                        <li class="mb-0 w-100 d-inline-block form-btn red-btn" v-if="verify">
                           <button style="background-color: var(--button-color);" type="button" @click="verifytelefon()">Verify<i class="fa fa-check" style="color:var(--button-color);"></i></button>
                        </li>
                       
                     </ul>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </section>



   <section class="contact-main-sec w-100 float-left padding-top padding-bottom" v-if="type=='login'">
      <div class="container">
         <div class="generic-title text-center wow bounceInUp" data-wow-duration="1s">
            <h2>Log In</h2>
            <p>Use your NightWise account<br></p>
         </div>
         <div class="row">
  
            <div class="col-lg-12">
               <div class="contact-form">
                  <form @submit.prevent="submitForm">
                     <ul class="list-unstyled mb-0">

                        <li class="float-left"><input class="w-100" type="email" placeholder="Email" name="username" id="email" v-model="loginform.email"></li>
                        <li class="float-left"><input class="w-100" type="password" placeholder="Password" name="password" id="password" v-model="loginform.password"></li>

                        <div class="form-check form-switch">
  <input class="form-check-input text-center" type="checkbox" id="flexSwitchCheckDefault" v-model="loginform.obavijesti" v-if="this.registerform.klubid">

  <label  for="flexSwitchCheckDefault"  class="form-check-label userlabel"  v-if="this.registerform.klubid">Želim primati email obavijesti o eventima od {{ this.$store.state.klubinfo.ime }}</label>
</div><br>

                       
                        <li class="mb-0 w-100 d-inline-block form-btn red-btn">
                           <button type="submit" style="background:#494B4E;">Log in<i class="fas fa-angle-right" style="color:#494B4E;"></i></button>
                        </li>
                     </ul>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </section>

   </template>
</Navigation>
       <!--navbar-->
       <!--banner-->
       
   <!--contact-sec-->
   <!--map-sec-->
  
    </template>
    
    
    <script>
    import Navigation from '../components/MainNavigation.vue'
    import axios from 'axios'
    import { VueTelInput } from 'vue3-tel-input'
    import 'vue3-tel-input/dist/vue3-tel-input.css'
    export default {
      name: 'HomeView',
      components: {
         Navigation,
         VueTelInput,
      },
      data(){
       return{
         bindprops: {
        preferredCountries: ["DE", "AT", "SI", "DK", "NL", "GB", "FR", "ES", "RS"],
      },

         verify:false,
          email:"",
          subscribe:false,
          type:"",

          loginform:{
            email:"",
            password:"",
            obavijesti:false,
            klubid: this.$store.state.klubid,
          },
          registerform:{
            name:"",
            password:"",
            email:"",
            phone:"",
            obavijesti:false,
            klubid: this.$store.state.klubid,
            kod:null,
          }
       }
       
      },
      mounted(){
       
         if(this.$route.params.klub != 'nightwise'){
            this.$root.getKlub(this.$route.params.klub)
         }
       $(".containerloading").hide()
       this.type = this.$route.params.type
       if(this.$route.params.klub == "nightwise" || !this.$store.state.klubid){
         console.log("nije")
         this.loginform.klubid = false
         this.registerform.klubid = false

       }
      },
    
      methods : {
         async verifytelefon() {

            console.log(this.registerform)
await axios
    .post("/api-token-auth/verify/", this.registerform)
    .then(response => {
  
         
        const token = response.data.token
        this.$store.commit('setToken', token)
        axios.defaults.headers.common["Authorization"] = "Token " + token
        


        localStorage.setItem("username", this.username)
        localStorage.setItem("token", token)
        localStorage.setItem("email", this.registerform.email)

        this.$store.state.email = this.registerform.email
         
        
        if(this.$route.params.klub == "nightwise"){
                     var toPath = '/'
         }
         else{
            var toPath = '/'+this.$route.params.klub
         }
        this.$router.push(toPath)

        this.$root.toastnoti("Uspjeh", "Your are registered", "uspjeh")

    })
    .catch(error => {

         
        this.$root.toastnoti("Greška", error.response.data, "greska")
    })
},

    
    
        async submitForm() {

   
            await axios
                .post("/api-token-auth/login/", this.loginform)
                .then(response => {
                  
                    const token = response.data.token
                    console.log(token)
                    this.$store.commit('setToken', token)
                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    
                    localStorage.setItem("username", this.username)
                    localStorage.setItem("token", token)
                    localStorage.setItem("email", this.loginform.email)
                    this.$store.state.email = this.loginform.email
              
              
                    if(this.$route.params.klub == "nightwise"){
                     var toPath = '/'
                    }
                    else{
            var toPath = '/'+this.$route.params.klub
         }
                    this.$router.push(toPath)

                    this.$root.toastnoti("Uspjeh", "You are logged in", "uspjeh")
                   

                })
                .catch(error => {
                  console.log(error)
                    this.$root.toastnoti("Greška", error.response.data, "greska")
                  

                })
        },

        async submitRegister() {
         this.registerform.phone = $('.vti__input').val()

   
await axios
    .post("/api-token-auth/register/", this.registerform)
    .then(response => {
      this.verify=true
      this.$root.toastnoti("Uspjeh", "Unesite kod kojeg smo vam poslali na sms poruku.", "uspjeh")

    })
    .catch(error => {

         
        this.$root.toastnoti("Greška", error.response.data, "greska")
    })
}
      }
    }
    </script>
    
    <style scoped>
    .vue-tel-input{
   border: none !important;
}
   </style>
<style>

.nightwisebtn a:hover{
   color: white;
}
.nightwisebtn a:hover i{
   background: white;
}

@media only screen and (max-width: 991px) {
   .nightwisetoggle {
      background: rgb(73, 75, 78) !important;
}

}

.form-check-input:checked {
   background-color: var(--footercolor);
   border-color: var(--footercolor);
}

</style>