<template>




<div class="containerloading" style="z-index: 9999;">
    <div class="dots">
        <div class="dot dot-1"></div>
        <div class="dot dot-2"></div>
        <div class="dot dot-3"></div>
    </div>
</div>


<div style="z-index: 1050;" class="toast-container position-fixed bottom-0 end-0 p-3 d-none" data-autohide="true">
  <div id="liveToast" class="toast " role="alert" aria-live="assertive" aria-atomic="true"  data-delay="3500">
    <div class="toast-header " style="color:white; font-weight:bold; background: linear-gradient(45deg,#2ed8b6,#59e0c5);">

      <i class="fa fa-info-circle rounded  me-2"></i>
      <strong style="cursor: pointer;" id="naslov" class="me-auto">Notifications</strong>

      <button type="button"  style="font-weight:bold;" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>


    </div>
    <div class="toast-body" style="color:rgb(106, 105, 105);">

      <span id="tekst">Sucess</span>
    </div>
  </div>

 
</div>

  <router-view/>
</template>



<script>
import axios from 'axios'
import store from './store'

export default{

  data(){
    return {
      
    }

  },
  
  beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.token
    if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token     
    } else {
        axios.defaults.headers.common['Authorization'] = ""

    }
  },


  mounted(){

  },
  methods:{

    async getKlub(klubparam) {
      console.log("zovem")
//this.$store.state.klubinfo.ime == klubparam
if(false){
    this.root = document.documentElement;
    this.root.style.setProperty("--navcolor", this.$store.state.klubinfo.navcolor);
    this.root.style.setProperty("--footercolor", this.$store.state.klubinfo.navcolor);
    this.root.style.setProperty("--footercolorfrom", this.$store.state.klubinfo.navcolor);
    this.root.style.setProperty("--footercolorfrom", this.$store.state.klubinfo.darkercolor);
}
else{


    axios
.get('/getklub/'+klubparam)
.then(res => {
  console.log(res.data)
  document.title =  res.data.ime
  localStorage.setItem("klubinfo", JSON.stringify(res.data))
  localStorage.setItem("klubid", res.data.id)
  this.$store.commit('setKlub', res.data)


    this.root = document.documentElement;
    this.root.style.setProperty("--navcolor", res.data.navcolor);
    this.root.style.setProperty("--footercolor", res.data.footercolor);
    this.root.style.setProperty("--footercolorfrom", res.data.footercolor);
    this.root.style.setProperty("--footercolorfrom", res.data.darkercolor);


})
.catch(error => {
this.$router.push('/404/klub/')


})

}




},



    toastnoti(naslov, tijelo, vrsta){
    
    if(vrsta=="uspjeh"){
    $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#2ed8b6,#59e0c5)');
  }
  else if(vrsta=="info"){
    $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#ed1650,#FF5370)');
  }
  else{
    $('#liveToast .toast-header').css('background', 'linear-gradient(45deg,#FF5370,#ff869a)');
    
  }
  $('.toast-container').removeClass('d-none');
  const toastLiveExample = document.getElementById('liveToast')
  
  $('#liveToast #tekst').text(tijelo);
  $('#liveToast #naslov').text(naslov);
  const toast = new bootstrap.Toast(toastLiveExample)
  toast.show()
  


},
  
  }
}
</script>

<style lang="scss">

.toast{
  border: none !important;
}

</style>



<style>
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Safari */
  -khtml-user-select: none;    /* Konqueror HTML */
  -moz-user-select: none;      /* Old versions of Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.containerloading {
  padding-top:150px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);

}
.containerloading2 {
  display: flex;
  justify-content: center;



}
.dots {
  position: relative;
  width: 150px;
  height: 50px;
  transform: scale(0.25, 0.25);
}
.dot {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;
  border-radius: 50%;
  background-color: #b9090c;
}
.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}
.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}
.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}
@-moz-keyframes animation-dots {
  0% {
    left: -150px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 100px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 200px;
  }
}
@-webkit-keyframes animation-dots {
  0% {
    left: -150px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 100px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 200px;
  }
}
@-o-keyframes animation-dots {
  0% {
    left: -150px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 100px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 200px;
  }
}
@keyframes animation-dots {
  0% {
    left: -150px;
    opacity: 0;
  }
  20% {
    left: 0px;
    opacity: 1;
  }
  80% {
    left: 100px;
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 200px;
  }
}

</style>