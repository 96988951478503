<template>
    <div class="w-100 float-left header-con">
      
      <div class="container">
         <nav class="navbar navbar-expand-lg navbar-light p-0">
       
            <a class="navbar-brand"> 
               <span v-if="account">
                  <i class="fa fa-moon" style="color:#494B4E;padding-left: 10px;font-size: 30px;" @click="$router.push('/')"></i>&nbsp;
                  <span style="color:#494B4E">NightWise</span>
                

                  
               </span>

               <span v-else>
                  <i class="fa fa-moon" style="color:white;padding-left: 10px;font-size: 30px;"></i>
               </span>
             

            </a>
            <div class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon navbar-toggler-icon2" :class="{ 'navbar-toggler-icon nightwisetoggle ': isNightMode }"></span>
               <span class="navbar-toggler-icon navbar-toggler-icon2" :class="{ 'navbar-toggler-icon nightwisetoggle ': isNightMode }"></span>
               <span class="navbar-toggler-icon navbar-toggler-icon2" :class="{ 'navbar-toggler-icon nightwisetoggle ': isNightMode }"></span>
        </div>
            <div class="collapse navbar-collapse navbar-collapse-main test2" id="navbarSupportedContent">

               <ul class="navbar-nav ml-auto">
                  <li class="nav-item active pl-lg-0" v-if="this.$store.state.klubinfo.ime">
                     <a class="nav-link p-0" :class="{ 'nav-link p-0 nightmode': isNightMode }" :href="'/'+this.$store.state.klubinfo.ime">BACK TO {{ this.$store.state.klubinfo.ime.toUpperCase() }}  <span class="sr-only">(current)</span></a>
                  </li>

                  <li class="nav-item active pl-lg-0">
                     <a class="nav-link p-0" :class="{ 'nav-link p-0 nightmode': isNightMode }" :href="'/'">NIGHTWISE HOME <span class="sr-only">(current)</span></a>
                  </li>

       
                  

               
              
         
             
               </ul>
               <hr style="color:white">
               <div class="navbar-btn d-inline-block">
                <a v-if="!this.$store.state.email" :href="'/account/nightwise/login/'" :class="{ 'nightmode': isNightMode }">NightWise Account <i class="fas fa-angle-right navbaricon"></i></a>
                  <a v-if="this.$store.state.email" :href="'/nightwise/user/'" :class="{ 'nightmode': isNightMode }">{{ this.$store.state.email }} <i class="fa fa-user navbaricon"></i></a>
               </div>

 
            </div>
         </nav>
      </div>

   </div>

<slot name="body" ></slot>

<section class="footer-main-sec w-100 text-center padding-top position-relative"   v-if="!hidefooter" style="margin-top: 100px;">
   <div class="container" >
         
         <!--form-sec-->
         <div class="form-sec d-flex align-items-center" style="position:relative !important;">
            <h3 class="mb-0">NightWise</h3>
               <div class="alert alert-warning" role="alert" v-show="subscribe">
Success! Thank you for subscribing to our nesletter
</div>
           <p>
         Rovinj, 52210, Croatia<br> Contact: +385 994351095<br> OIB: 54836231721<br>IBAN: LT303250092609461757</p>
          
         </div>
      
         <!--form-sec-->

      </div>
   </section>

</template>

<script>

import axios from 'axios';

export default {
  name: 'MainNav',
  components: {



  },
  props: {
   showlogo:false,
  },

  data(){
    return{
      account:false,
    }
  },
  computed: {
  
    isNightMode() {
      
      if(this.$route.name == 'register' || this.$route.name == 'checkout' || this.$route.name == 'profile'){
         return true
      }
   
    }
  },

  mounted(){
   
   if(this.showlogo || this.$route.name === 'register'){
      this.account = true
      console.log(this.account)
   }


  },
  methods:{
   async togglepolicy(tekst) {
      $("#" + tekst).toggle();
    },

}
}
</script>
<style>
@media screen and (min-width: 768px) {
   .nightmode{
   color:black !important;
}
}

@media only screen and (max-width: 991px){
.navbar-collapse-main {
    background: #494B4E;
    text-align: center;
    border-radius: 5px;
    padding: 0 20px;
}
}


.navbar-collapse-main{
    margin-top: 10px;
}

.navbar-btn a:hover i{
color:white !important;
}
.navbaricon{
   color:#494B4E !important;
}
</style>
