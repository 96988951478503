<template>
<Navigation :hidefooter="false" :showlogo="true">
  <template #body>
    <section class="sub-banner-sec w-100 float-left d-table position-relative">
        <img :src="this.$store.state.klubinfo.bg" class="section-background-image">
    <div class="overlay" ></div>
      <div class="d-table-cell align-middle">
         <div class="container">
            <div class="banner-inner-con text-white text-center wow bounceInUp" data-wow-duration="1s">
               <h1 class="position-relative">Event Details</h1>
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb d-inline-block">
                  <li class="breadcrumb-item d-inline-block"><a class="text-white" :href="'/'+this.$store.state.klubinfo.ime">Home</a></li>

                  <li class="breadcrumb-item active text-white d-inline-block" aria-current="page">Event Details</li>
                  </ol>
               </nav>
            </div>
         </div>
      </div>
   </section>
 <!--banner-->
   <!--conference-sec-->
   <section class="event-detail-sec  float-left padding-top padding-bottom">
    <div class="container">
         <div class="event-details-main-con  float-left">
            <div class="event-image-con blog-img position-relative wow bounceInUp" data-wow-duration="1s">
               <figure class="">
     
                  <template v-if="event.slike && event.slike[0] && event.slike[0][0] !== undefined">
                              <img :src="event.slike[0][0]" alt="blog-img1">
                           </template>
                           <template v-else>
                              <img src="../assets/images/blog-img1.jpg" alt="blog-img1">
                           </template>

               </figure>
               <div class="date text-white"><span>{{dan(event.datum)}}</span><small>{{mjesec(event.datum)}}</small></div>
            </div>
            <div class="event-details wow bounceInUp" data-wow-duration="1s">
               <div class="admin-con">
                  <ul class="list-unstyled d-inline-block">
               
                     <li class="d-inline-block">
                        <i class="fas fa-map-marker-alt"></i>
                        <span>{{ event.lokacija }}</span>
                     </li>
                  </ul>
               </div>
               <h2>{{event.naslov}}</h2>
               <p>{{event.opis}}</p>
               <p v-if="!event.prodajakarta" style="font-weight: bold;">Ticket sale is not available for now</p>
               <h2 @click="showtickets()"  v-else style="padding-bottom: 15px;cursor: pointer;" class="no-select">Tickets  <i :class="ticketshow ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i></h2>
               <span v-if="ticketshow">

                <template v-for="i in event.tickets" >
            
            <div @click="this.otvoriticket(i[5], 'ticketModal')" style="padding-bottom:10px;padding-right: 5px;" class="generic-button red-btn d-inline-block" v-if="i[4]>0" >
              <a style="color:white;cursor:pointer">  {{ i[0] }}  {{i[1]}}€<i class="fa fa-ticket"></i></a>
           </div> 

          </template>

               </span>
          
       
              
               <div class="generic-button red-btn d-inline-block" v-if="!this.$store.state.isAuthenticated">
                  <a :href="'/account/' + $store.state.klubinfo.ime + '/login/'">Log in for table reservations! <i class="fas fa-angle-down"></i></a>
                  <br><br>
               </div>
            
               <h4 v-if="this.$store.state.isAuthenticated && this.event.plansvg && this.event.rezervacije != 2">Reserve a table:</h4>
               <p v-if="!this.event.plansvg || this.event.rezervacije == 2" style="font-weight: bold;">Table reservation is not available for now</p>
               <div v-html="this.svg" :class="classklub()" @click="handleSvgClick" v-if="this.event.plansvg && this.event.rezervacije != 2" ></div>
            </div>
            
         </div>

    
<br><br><br><br>
    </div>
   </section>
  
<div class="alert alert-success fixed-top" role="alert" v-show="rezervacijauspjeh">
Success! Thank you for reservation, please check email for the confirmation. Check the spam if it has not arrived!
</div>






   <!--gallery-sec-->
   <div class="gallery-sec w-100 float-left padding-top padding-bottom text-center" v-if="event.slike && event.slike.length>1">
      <div class="container">
         <div class="navbar-btn d-inline-block">
               
               </div>
         <div class="masonry">
       <template  v-for="(item, index) in event.slike">
         <div class="masonry-item cell cell-1 panel-body wow bounceInUp" data-wow-duration="1s" v-if="index!=0">
               <a href="#" class="zoom" data-toggle="modal" data-target="#lightbox">
                  <img :src="item[0]" alt="">
                  <span><i class="fa-light fa-plus-large"></i></span>
               </a>
            </div>
         </template>
           
          
           
         </div>
       
      </div>
   </div>
   <!--gallery-sec-->
<div style="height: 150vh;" v-if="event.slike && event.slike.length<=1"></div>
  </template>

</Navigation>
  

<div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel">Reserve a table</h4>
            <i class="fa fa-circle-xmark" style="color:#ed1650;font-size: 30px;cursor: pointer;"  @click="this.zatvoriform('rezervirajModal')" ></i>
  
          </div>
          <div class="modal-body">
   
   
            <form style="padding:10px">

              <p style="color:gray;font-weight: bold;">Table number: {{ this.form.stol }} </p>
              <p style="color:gray;font-weight: bold;">Min Person: {{ this.form.info[1] }}</p>

              <p style="color:gray;font-weight: bold;" v-if="this.event.rezervacije==1">Deposit amount: {{ this.form.info[0] }}€</p>
              <p v-else style="color:gray;font-weight: bold;">Make a reservation for free</p>
			
				<p style="color:gray;" >Reservation will be connected to your NightWise account </p>
  

        <div class="form-check form-switch">

</div>




          </form>
  
          </div>
          <div class="modal-footer d-flex justify-content-center">

            <button class="btn btn-warning f-left modalbutton" style="color:White;background-color: #ed1650;border:none;" data-dismiss="modal" @click="rezerviraj()">Reserve</button>
      

         
  
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="ticketModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel">Buy a ticket</h4>
            <i class="fa fa-circle-xmark" style="color:#ed1650;font-size: 30px;cursor: pointer;"  @click="this.zatvoriform('ticketModal')" ></i>
  
          </div>
          <form style="padding:10px" @submit.prevent="buytickets()">
          <div class="modal-body" style="padding-bottom: 10px;">
   
            
           


				<br>
        <p>Ticket can be bought until <b style="font-weight: bold;">{{ new Date(this.do).toLocaleString('uk', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</b></p>
				<p style="color:gray;">Price per ticket: <b style="font-weight: bold;">{{ticketform.cijena}}€</b></p>
        <div class="input-group">
    <span class="input-group-btn">
        <button class="btn btn-secondary btn-minuse" type="button" @click="smanjikolicina" style="background-color: var(--button-color);border: none;"><i class="fa fa-minus-circle" style="color:white"></i></button>
    </span>
    <input type="text" class="form-control no-padding add-color text-center" maxlength="2" v-model="this.ticketform.kolicina" style="padding: 0;">
    <span class="input-group-btn">
        <button class="btn btn-red btn-pluss"  style="background-color: var(--button-color);border: none;" type="button"  @click="this.ticketform.kolicina+=1"><i class="fa fa-plus-circle" style="color:white"></i></button>
    </span>
</div><!-- /input-group -->

              <br>
        <p style="color:gray;font-weight:bold">Total: {{ ticketform.kolicina*ticketform.cijena }}€</p>
    



        <p v-if="!this.$store.state.isAuthenticated">Since you do not have <b style="font-weight: bold;">NightWise</b> account, please enter email where you want to get the tickets.</p>
        <input class="form-control" type="email" v-if="!this.$store.state.isAuthenticated" v-model="ticketform.email" style="padding-bottom: 10px;">
         
         
        <div class="form-check form-switch">

<input class="form-check-input text-center" type="checkbox" id="flexSwitchCheckDefault" v-model="ticketform.policy">

<label  for=""  class=" userlabel" style="float:left">I accept <b style="font-weight: bold;" @click="togglepolicy('policytekst')">Sales Policy</b></label>
</div>

      <p style="display:none;" id="policytekst">Eligibility: Users must be 18 years or older to purchase tickets and may be required to present valid ID at the event venue.
Payment Method: Tickets must be purchased through our secure online payment system. We accept major credit/debit cards and digital wallets.
Pricing and Availability: Ticket prices are set by the event organizers and are subject to change based on demand. Tickets are sold on a first-come, first-served basis.
Refunds and Cancellations
Refund Policy: All ticket sales are final. Refunds are only available if an event is canceled or rescheduled. Service fees are non-refundable.
Event Cancellation: In the event of cancellation, ticket holders will be notified via email and will receive a full refund within a specified period.
Rescheduling: If an event is rescheduled, ticket holders will have the option to retain their tickets for the new date or request a refund.
User Account
Account Responsibility: Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.
Ticket Transfer: Tickets are non-transferable and must be used by the person whose name is on the ticket.
Privacy and Security
Data Protection: NightWise is committed to protecting the privacy and security of our users. Personal information is processed in accordance with our Privacy Policy.
Transaction Security: All payment transactions are encrypted and processed through a secure gateway.
Limitation of Liability
NightWise is not liable for any personal or property damages or losses that may occur at any event. Attendance is at the ticket holder’s own risk.

Changes to Policy
NightWise reserves the right to modify this sales policy at any time. Changes will be effective immediately upon posting on our platform.</p>
            

  
          </div>
          <div class="modal-footer d-flex justify-content-center" >

            <button class="btn btn-warning f-left modalbutton" style="color:White;background-color: #ed1650;border:none;" type="submit">Go to Payment</button>
      

         
  
          </div>
        </form>
        </div>
      </div>
    </div>

</template>


<script>
import 'vue3-tel-input/dist/vue3-tel-input.css'
import { VueTelInput } from 'vue3-tel-input'
import axios from 'axios'
import Navigation from '../components/Navigation.vue'
export default {
  name: 'HomeView',
  components: {
    VueTelInput,
    Navigation
  },
  data(){
   return{
    ticketshow:false,
      id:0,
      slobodnistolovi:[],
      event:"",
      bindprops: {
        preferredCountries: ["DE", "AT", "SI", "DK", "NL", "GB", "FR", "ES", "RS"],
      },
      form:{
        
         ideventa:0,
      stol:0,
			telefon:"",
			ime:"",
      email:"",
      info:[],
      policy:false
      
		},
    ticketform:{
      klubid:this.$store.state.klubid,
      ticketid:null,
      cijena:null,
   
        email:"",
        auth:false,
        kolicina:1,
        eventid:0,
        token:"",
        policy:false,
      },

      od:"",
      do:"",
      naslov:"",
  
      rezervacijauspjeh:false,
      svg:null
   }
   
  },


  mounted(){
    $(".containerloading").show()
    if(this.$store.state.email){
      this.ticketform.email = this.$store.state.email
    }
   this.id = this.$route.params.id
   this.ticketform.eventid = this.id
   this.form.ideventa = this.id
   this.getEvent()
   this.ticketform.auth = this.$store.state.isAuthenticated
   console.log(this.$store.state.isAuthenticated)
  },

  methods : {
    showtickets(){
      this.ticketshow = !this.ticketshow
    },
    otvoriticket(id, modalname){
    console.log(id)
    $('#' + modalname + ' ' +  '.modal-content').removeClass('animate-down').addClass('animate-bottom');
    $("#"+modalname).modal('show');   
    let ticket = this.event.tickets.filter(i => i[5] === id);
    this.ticketform.cijena = ticket[0][1]
    this.ticketform.ticketid = id
    this.naslov = ticket[0][0]
    this.od = ticket[0][2]
    this.do = ticket[0][3]



},

    async togglepolicy(tekst) {
      $("#" + tekst).toggle();
    },

    smanjikolicina() {
      if (this.ticketform.kolicina > 1) {
        this.ticketform.kolicina -= 1;
      }
    },


    handleSvgClick(event) {
      console.log(event.target)
      const target = event.target;
      if(!this.$store.state.isAuthenticated){
        this.$root.toastnoti("Account required", 'Log in for reservations', "info")
        return;
      }
      if (target.tagName === "path") {
        const id = target.getAttribute("id");
        this.otvorimodal(id)
      }
    },
    otvorimodal(stol){
      try {
        let filteredList = this.event.plan.filter(item => item.stol == stol);
    console.log(filteredList)

    if(filteredList[0].lista[2])  {

      this.form.stol = stol
      this.form.info = filteredList[0].lista
      this.form.deposit = this.form.info[0]
      $("#rezervirajModal").modal("show")
    }
    else{

      this.$root.toastnoti("Error", 'Table is already reserved, click on the green ones.', "greska")
    }
      }

 
    catch{
      this.$root.toastnoti("Error", 'An error ocured, please try again later.', "greska")
    }
    

      
   },



   async fetchSvg(plansvg) {

await axios
.get(plansvg)
.then(res => {


const parser = new DOMParser();

const svgDoc = parser.parseFromString(res.data, 'image/svg+xml');


for(var i=0; i<this.event.plan.length; i++){

  let svgPath = svgDoc.querySelector(`path[id="${this.event.plan[i].stol}"]`)
  if (svgPath){
    if(this.event.plan[i].lista[2]){
    svgPath.classList.add('hover-slobodno');
   
  }
  else{

    svgPath.classList.add('hover-zauzeto');

  }
  
  }
  


  

}

const modifiedSvgString = new XMLSerializer().serializeToString(svgDoc);

  this.svg = modifiedSvgString





})
.catch(error => {

 console.log(error)

})
},

    klubmodel(){
      if(this.$store.state.isAuthenticated){
        return "klubsvg isauthklub"
      }
      else{
        return "klubsvg notauthklub"
      }

    },
   
   dan(datum) {
    // Parse the date string into a Date object
    const dateObj = new Date(datum);

    const options = { day: "2-digit", month: "short" };
    return dateObj.toLocaleDateString("en-US", options).split(" ")[1].toUpperCase();
   

  },
  classklub(){
    if(this.$store.state.isAuthenticated){ return "svgklub isauthklub"}
    else{ return "svgklub notauthklub "}
  },

  mjesec(datum) {
    // Parse the date string into a Date object
    const dateObj = new Date(datum);

    const options = { day: "2-digit", month: "short" };

    
    return dateObj.toLocaleDateString("en-US", options).split(" ")[0];
  },

  rezerviraj(){
    $(".containerloading").show()

    if(this.event.rezervacije!=1){
        this.form.deposit = 0
      }
    axios.post('/guestrezerviraj/', this.form)
    .then((res) => {

   

      const index = this.slobodnistolovi.indexOf(this.form.stol);

      if (index !== -1) {
        this.slobodnistolovi.splice(index, 1); 
      } 
      let stolsvg = $('path#'+this.form.stol)
  
      stolsvg.removeClass('hover-slobodno').addClass('hover-zauzeto');
      let filteredindex = this.event.plan.findIndex(item => item.stol == this.form.stol);
      this.event.plan[filteredindex].lista[2] = false

      this.$root.toastnoti("Success!", 'Table is reserved, check more detaisl at profile page', "uspjeh")
      $("#rezervirajModal").modal("hide")
      $(".containerloading").hide()

      
  

            
      })
    .catch((error) => {
      $(".containerloading").hide()
      if(error.response.status == 401){
        $("#rezervirajModal").modal("hide")
        this.$router.push('/'+this.$store.state.klubinfo.ime + '/account/login/')
                }
     
    })
},  

buytickets(){
  console.log(this.ticketform)
  $(".containerloading").show()
    if(!this.ticketform.policy){
      this.$root.toastnoti("Error", 'Please confirm sales policy to proceed', "greska")
      $(".containerloading").hide()
      return;
    }
    if(this.$store.state.isAuthenticated){
      this.ticketform.auth = true
      this.ticketform.token = this.$store.state.token
   
    }
    axios.post('/stripe/ticket/', this.ticketform)
    .then((res) => {

      
      $(".containerloading").hide()
      this.zatvoriform("ticketModal")
      localStorage.setItem("orderdata", JSON.stringify(res.data.order))
      const secretresponse = {'clientSecret':res.data.clientSecret}
      localStorage.setItem("checkoutresponse", JSON.stringify(secretresponse))
      this.$store.commit('setCheckout', secretresponse)
      this.$router.push('/stripe/checkout/')
  

            
      })
    .catch((error) => {
      $(".containerloading").hide()
      this.$root.toastnoti("Error", 'Error while buying tickets, please try again later.', "greska")
      this.zatvoriform("ticketModal")
     
    })
},  





   async getEvent() {
   

   await axios
     .get('/eventidetaljiguest/'+this.id)
     .then(res => {
       this.event = res.data
       console.log(this.event)
       
       this.slobodnistolovi = this.event.stolovi
       if(this.event.plansvg){
        this.fetchSvg(this.event.plansvg)
       }
       
       $(".containerloading").hide()

   
     })
     .catch(error => {
   
       console.log(error)
   
     })
   
   },

   zatvoriform(modalname){

$('#' + modalname + ' ' +  '.modal-content').removeClass('animate-bottom').addClass('animate-down');
 $("#"+modalname).modal('hide');   



},
otvoriform(modalname){

$('#' + modalname + ' ' +  '.modal-content').removeClass('animate-down').addClass('animate-bottom');
 $("#"+modalname).modal('show');   



},




   


  }
}
</script>

<style>
@media screen and (min-width: 1000px) {
    .sub-banner-sec{
        padding-top: 150px;
        padding-bottom: 80px
    }
}

img{
   pointer-events: none;
}
path {
  pointer-events: all; 
}

.hover-slobodno:hover {
    fill: green; /* Change to the desired color on hover */
	fill-opacity: 0.5; 
  }

  .hover-slobodno {
    fill: green; /* Default fill color */
    fill-opacity: 0.3; /* Default fill opacity */
	cursor: pointer;
  }

  .hover-zauzeto {
    fill: red; /* Default fill color */
    fill-opacity: 0.5; /* Default fill opacity */
	cursor: not-allowed;
  }
  </style>


<style scoped>

.notauthklub{
padding: 5px;
pointer-events: none;
opacity: 0.3;
}


.modal-dialog{
   top:0% !important;
   text-align: center;
}

@media only screen and (min-width: 600px) {
  .svgklub {
   
   

  
  }
}
</style>