<template>


   <!--navbar-->
   <!--banner-->
<Navigation :hidefooter="false" :showlogo="true">
   <template #body>

      <section class="banner-main-sec w-100 float-left d-table position-relative" style="height: 280px;">
      
         <img :src="this.$store.state.klubinfo.bg" class="section-background-image" >
         
         <div class="overlay" ></div>
             <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-sec text-white d-inline-block">
    
               <span class="wow bounceInUp d-inline-block" data-wow-duration="1s"></span>
               <!-- <h1 class="position-relative wow bounceInUp" data-wow-duration="1s">{{$store.state.klubinfo.ime}}</h1>-->
               <p class="wow bounceInUp" data-wow-duration="1s">Make your night even better 
             </p>
               <div class="generic-button white-btn d-inline-block wow bounceInUp" data-wow-duration="1s" style="">
                  <a v-if="!this.$store.state.email" @click="$router.push('/account/'+$store.state.klubinfo.ime + '/login/')" style="color:white;background: #494B4E !important;">NightWise Account <i class="fas fa-angle-right" style="color:#494B4E;background: white !important;"></i></a>
                  <a v-if="this.$store.state.email" @click="$router.push('/nightwise/user/')" style="color:white;background: #494B4E !important;cursor: pointer;">{{ this.$store.state.email }} <i class="fa fa-user" style="color:#494B4E;background: white !important;"></i></a>
               </div><br>
            </div>
         </div>
         <!--
         <div class="banner-social-icons" >
            <ul class="list-unstyled p-0 text-center mb-0">
               <li class="wow bounceInUp" data-wow-duration="1s"><a class="d-inline-block" href="https://www.facebook.com/"><i class="fab fa-facebook-f"></i></a></li>
               <li class="wow bounceInUp" data-wow-duration="1s"><a class="d-inline-block" href="https://www.facebook.com/"><i class="fab fa-instagram"></i></a></li>
  
               <li class="wow bounceInUp" data-wow-duration="1s"><a class="d-inline-block" href="https://twitter.com/i/flow/login"><i class="fab fa-youtube"></i></a></li>
           
            </ul>
         </div>
            -->
      </div>

   </section>
   <!--banner-->
   <!--conference-sec-->


   <section class="blog-main-sec w-100 float-left padding-top position-relative" id="eventi" style="padding-bottom: 50px;">
      <div class="container">
         <div class="generic-title text-center">
            <h2 class="wow bounceInUp" data-wow-duration="1s">Upcoming Events <i class="fa fa-fire" style="color:orange"></i></h2>
            <p class="wow bounceInUp" data-wow-duration="1s">Click on the event for more information. <br></p>
         </div>
         <div class="row">
            <div class="col-lg-4" v-for="i in eventi" @click="$router.push('/details/'+$store.state.klubinfo.ime + '/'+i[5])">
               <div class="blog-con">
                  <div class="blog-img">
                     <a href="#" data-toggle="modal" data-target="#blog1">
                        <figure class="mb-0 wow bounceInUp" data-wow-duration="1s">
                           <template v-if="i[4] && i[4][0] && i[4][0][0] !== undefined">
                              <img :src="i[4][0][0]" alt="blog-img1" class="eventslika">
                           </template>
                           <template v-else>
                              <img src="../assets/images/blog-img1.jpg" alt="blog-img1" class="eventslika">
                           </template>
                           
                        </figure>
                     </a>
                     <div class="date text-white wow bounceInUp" data-wow-duration="1s"><span>{{dan(i[3])}}</span><small>{{mjesec(i[3])}}</small></div>
                  </div>
                  <div class="blog-inner-con">
                     <div class="admin-con wow bounceInUp" data-wow-duration="1s">
                        <ul class="list-unstyled d-inline-block">
                    
                           <li class="d-inline-block">
                              <i class="fas fa-map-marker-alt"></i>
                              <span>{{ i[2] }}</span>
                           </li>
                        </ul>
                     </div>
                     <a href="#" data-toggle="modal" data-target="#blog1">
                        <h5 class="wow bounceInUp" data-wow-duration="1s">{{i[0]}}</h5>
                     </a>
                     <p class="wow bounceInUp" data-wow-duration="1s">{{i[1]}} </p>
                     <div class="blog-arrow wow bounceInUp" data-wow-duration="1s">
                        <a class="d-inline-block" :href="'/details/'+$store.state.klubinfo.ime + '/'+i[5]" ><i class="fa fa-ticket"></i></a>
                     </div>
                  </div>
               </div>
            </div>

            <div class="col-lg-4" v-if="eventi && !eventi.length">
               <div class="blog-con">
         
                  <div class="blog-inner-con">
                     <div class="admin-con wow bounceInUp" data-wow-duration="1s">
                   
                     </div>
                     <a href="#" data-toggle="modal" data-target="#blog1">
                        <h5 class="wow bounceInUp" data-wow-duration="1s">No events</h5>
                     </a>
                     <p class="wow bounceInUp" data-wow-duration="1s">Check later </p>
                 
                  </div>
               </div>
            </div>
            
          
           
         </div>
      </div>
   </section>

   <section class="schedule-sec w-100 float-left padding-top padding-bottom">
      <div class="container">
         <div class="schedule-inner-sec text-center">
            <div class="generic-title">
               <h2 class="wow bounceInUp" data-wow-duration="1s">Past events &nbsp;<i class="fa fa-calendar" style="color:orange"></i></h2>
               <p class="wow bounceInUp" data-wow-duration="1s"></p>
            </div>
            <div id="tsum-tabs">

               <template v-for="(item, index) in zadnja3mjeseca">
        
                  <template v-if="index==0"> <input :id="'tab'+(index+1)" type="radio" name="tabs" checked></template>
                  <template v-else><input :id="'tab'+(index+1)" type="radio" name="tabs"></template>
                  
               <label :for="'tab'+(index+1)" class="Effective-sec-item mb-lg-0 Effective-sec-item-title wow bounceInUp Effective-sec-item-img" data-wow-duration="1s">
               <span class="mb-0 d-block">{{item}}</span>
                          
               </label>

               </template>
              
               <template v-for="(item, index) in proslieventi">

                  <section :id="'content'+(this.zadnja3mjeseca.indexOf(index)+1)" >
                 
                 <div class="tabs-content d-flex align-items-center wow bounceInUp" data-wow-duration="1s" v-for="i in item">
                  <div class="value-sec float-left">
                        <div class="count float-left"> {{dan(i[3])}} </div>
                        <div class="date-con d-inline-block">
                           <span>{{index}}</span>
                         
                        </div>
                     </div>
                    <div class="detail-sec d-flex align-items-center">
                       <figure class="mb-0">
                      
                       </figure>
                       <div class="admin-con">
                          <h5>{{ i[0] }}</h5>
                          <ul class="mb-0 list-unstyled">
                             <li class="d-inline-block">
                               
                                <span>{{i[1]}}</span>
                             </li><br>
                             <li class="d-inline-block">
                                <i class="fas fa-map-marker-alt"></i>
                                <span>At: {{i[2]}}</span>
                             </li>
                          </ul>
                       </div>
                      
                    </div>
                 </div>
                 <div class="tabs-content d-flex align-items-center wow bounceInUp" data-wow-duration="1s" v-if="item && !item.length">
                  <div class="value-sec float-left">
                        <div class="count float-left">  </div>
                        <div class="date-con d-inline-block">
                           <span>Nema eventa</span>
                         
                        </div>
                     </div>
                    <div class="detail-sec d-flex align-items-center">
                
                       <div class="admin-con">
                          <h5></h5>
                          <ul class="mb-0 list-unstyled">
                             <li class="d-inline-block">
                               
                                <span></span>
                             </li><br>
                     
                          </ul>
                       </div>
                      
                    </div>
                 </div>


              </section>

               </template>
               



            </div>
         </div>
      </div>
   </section>
   <section class="service-main-sec w-100 float-left  padding-bottom">
      <div class="container">
         <div class="generic-title text-center">
            <h2 class="wow bounceInUp" data-wow-duration="1s"><i class="fa fa-moon" style="color:gray"></i>&nbsp;NightWise makes your experience so much better</h2>
     <br><br>
         </div>
         <div class="row">

            <div class=" col-lg-6 col-md-6 col-sm-12" @click="$router.push('/loyalty/'+ this.$store.state.klubinfo.ime)">
               <div class="service-box text-center">
                  <figure class="wow bounceInUp" data-wow-duration="1s">
                     <img src="../assets/images/loyalty.png" alt="service-icon2" style="width: 20%;height: auto;" >
                  </figure>
                  <h4 class="wow bounceInUp" data-wow-duration="1s">Loyalty</h4>
                  <p class="mb-0 wow bounceInUp" data-wow-duration="1s">Collect points by ordering drinks and buying tickets for events. Points can then be used for rewards. <br>
                     </p>
               </div>
            </div>


            <div class=" col-lg-6 col-md-6 col-sm-12">
               <a href="#eventi" style="text-decoration:none;">
               <div class="service-box text-center">
                  <figure class="wow bounceInUp" data-wow-duration="1s">
                     <img src="../assets/images/ticket.png" alt="service-icon1" style="width: 20%;height: auto;">
                  </figure>
                  <h4 class="wow bounceInUp" data-wow-duration="1s">Tickets</h4>
                  <p class="mb-0 wow bounceInUp" data-wow-duration="1s" style="color:gray">Buy discounted tickets for events through our platform. <br></p>
               </div>
            </a>
            </div>
        


            <div class="col-lg-6 col-md-6 col-sm-12" >
               <a href="#eventi" style="text-decoration:none;">
               <div class="service-box text-center mb-0">
                  <figure class="wow bounceInUp" data-wow-duration="1s">
                     <img  src="../assets/images/service-icon1.png" alt="service-icon4" style="width: 20%;height: auto;">
                  </figure>
                  <h4 class="wow bounceInUp" data-wow-duration="1s">Table Reservations</h4>
                  <p class="mb-0 wow bounceInUp" data-wow-duration="1s" style="color:gray">Easily chooose tables from club plan and make a reservation in seconds. <br>
                     </p>
               </div>
            </a>
            </div>
       
            <div class=" col-lg-6 col-md-6 col-sm-12" @click="$router.push('/musicorder/'+ this.$store.state.klubinfo.ime+'/1')">
               <div class="service-box text-center">
                  <figure class="wow bounceInUp" data-wow-duration="1s">
                     <img  src="../assets/images/bottles.png" alt="service-icon2" style="width: 20%;height: auto;">
                  </figure>
                  <h4 class="wow bounceInUp" data-wow-duration="1s">Drink Ordering</h4>
                  <p class="mb-0 wow bounceInUp" data-wow-duration="1s">Order drinks through the app and collect NightWise points. Choose between collect at bar or order to table option. <br>
             </p>
               </div>
            </div>
            <div class=" col-lg-6 col-md-6 col-sm-12" @click="$router.push('/musicorder/'+ this.$store.state.klubinfo.ime+'/1')"  v-if="this.$store.state.klubinfo.music">
               <div class="service-box text-center">
                  <figure class="wow bounceInUp" data-wow-duration="1s">
                     <img  src="../assets/images/music.png" alt="service-icon2" style="width: 18%;height: auto;">
                  </figure>
                  <h4 class="wow bounceInUp" data-wow-duration="1s">Music Suggestions</h4>
                  <p class="mb-0 wow bounceInUp" data-wow-duration="1s">Tell DJ what song you want to hear for tailored experience. <br>
             </p>
               </div>
            </div>


         

            
            
         </div>
      </div>
   </section>
<div style="height: 100px;"></div>

   </template>
</Navigation>
   




</template>

<script>
import axios from 'axios'
import Navigation from '../components/Navigation.vue'
export default {
  name: 'HomeView',
  components: {
   Navigation

  },
  data(){
   return{
      eventi:[],
      zadnja3mjeseca:[],
      proslieventi:{},
      email:"",
      subscribe:false,
      klubdata:[],
      loadcount:0,

   }
   
  },
   mounted(){

      $(".containerloading").show() 

   console.log("klubdata je ", this.klubdata)
   this.calculateLastThreeMonths()
   this.getEventi()

   
   
  },


  methods : {
   loaded(){
      this.$store.state.loadcount+=1
      console.log("COUNT JE ", this.$store.state.loadcount)
      $(".containerloading").hide()
  
  
      
   


   },




   dan(datum) {
    // Parse the date string into a Date object
    const dateObj = new Date(datum);

    const options = { day: "2-digit", month: "short" };
    return dateObj.toLocaleDateString("en-US", options).split(" ")[1].toUpperCase();
   

  },

  mjesec(datum) {
    // Parse the date string into a Date object
    const dateObj = new Date(datum);

    const options = { day: "2-digit", month: "short" };

    
    return dateObj.toLocaleDateString("en-US", options).split(" ")[0];
  },

  calculateLastThreeMonths() {
   const currentDate = new Date();
      const lastThreeMonths = [];

      for (let i = 0; i < 3; i++) {
        const month = currentDate.toLocaleString('en-US', { month: 'long' });
        lastThreeMonths.push(month);

        // Move to the previous month
        currentDate.setMonth(currentDate.getMonth() - 1);
      }

      this.zadnja3mjeseca = lastThreeMonths; // Reverse the order to have the current month first
      this.proslieventi[this.zadnja3mjeseca[0]] = []
      this.proslieventi[this.zadnja3mjeseca[1]] = []
      this.proslieventi[this.zadnja3mjeseca[2]] = []
    },


   async getEventi() {
   
      

   await axios
     .get('/eventiguest/'+this.$route.params.klub)
     .then(res => {
       this.eventi = res.data.eventi
       console.log(this.eventi)

       const today = new Date();
       

this.eventi = this.eventi.filter(sublist => {

  const dateString = sublist[3];
  const date = new Date(dateString);
  date.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  if (date < today) {
    const month = date.toLocaleString('default', { month: 'long' });

    if (!this.proslieventi[month]) {

    }

    this.proslieventi[month].push(sublist);

    
  }
  else{
   
   return sublist
  }


});


$(".containerloading").hide() 
       
   
   
     })
     .catch(error => {
      $(".containerloading").hide()

       console.log(error)
   
     })
     .finally(() => {


        });
   
   },

   
  
  }
}
</script>


<style>
@media (min-width: 768px) {
   .bannerklub{
   height: 50vh;
}
}

.eventslika {
        width: 100%;
        max-height: 45vh; /* Set a fixed height for your images */
        object-fit: cover;
        object-position: center top;
    }

.bannerklub{
   background: url(../assets/images/bg1.jpg) no-repeat center bottom;
   background-size :cover
}
 .container {
   
            width: 100%;
          

        }
        @media (min-width: 769px) {
         .overlay {
            
         
            border-radius: 0 0 0 400px;
        }

        }

         .overlay2 {

            border-radius: 0 0 0 400px;;
        

        }
        .overlay {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5); /* Adjust the last value (0.5) to control the darkness */
      
        }

        .content {
        
        
            z-index: 1; /* Ensure the content appears above the overlay */
            padding: 20px; /* Adjust padding as needed */
            color: #ffffff; /* Set text color for the content */
        }

        @media only screen and (min-width: 992px){
         .banner-inner-sec{
            margin-left:80px;
         }
  
        }

        .section-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This will ensure that the image covers the entire section */
  z-index: -1; /* This ensures the image stays in the background */
}

</style>