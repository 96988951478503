<template>




<MainNavigation :hidefooter="true" :showlogo="true">
       <template #body>


        <div id="checkout">
 
</div>
 
       </template>
    </MainNavigation>




 


</template>

<style scoped>

#checkout {
    /* mobile-specific styles */

    padding-top: 20%;
}

/* Media query for larger screens (e.g., tablets, desktops) */
@media screen and (min-width: 768px) {
    #checkout {
       padding-top: 10%;
    }
}

</style>
<script>
import axios from 'axios'
import MainNavigation from '../components/MainNavigation.vue'
export default {
  name: 'Uspjeh',
  data(){
    return{


     checkout:null,
     stripe:null,
  
      
    }
  },
  components: {
            MainNavigation
          },
    beforeDestroy() {
        this.$root.toastnoti("Error", 'Error while buying tickets, please try again later.', "greska")

        this.checkout.destroy()
        this.stripe.destroy()
        this.stripe = null
    
  },
  beforeRouteLeave(to, from, next) {

    this.checkout.destroy()
    next()

  },


  mounted(){
    $(".containerloading").hide()
    this.initialize()



  },
  methods:{
  
    async  initialize() {

        const data = this.$store.state.checkoutresponse
 
        this.stripe = Stripe("pk_live_51OZbJbL9tun5nRinn0997NrT9m2LPedu539rpnUGP5ZJJWyzY3nYfDBWkXJ6a4EBa9iDCapt168TCNuIEBL03F4000q2cN8jiS");

        const { clientSecret } = data;
        this.checkout = await this.stripe.initEmbeddedCheckout({
        clientSecret,
        });

    this.checkout.mount('#checkout');
  } 

  }
  
  
}
</script>


<style scoped>
body {
  display: flex;
  justify-content: center;
  background: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Helvetica Neue', 'Ubuntu', sans-serif;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
section {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 112px;
  border-radius: 6px;
  justify-content: space-between;
}
p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.154px;
  color: #242d60;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
#checkout {
  width: 100vw;
}
.hidden {
  display: none;
}</style>