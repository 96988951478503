import { createStore } from 'vuex'

export default createStore({
  state: {
    cart: {
        items: [],
        rewards:[],
        klubid:null,
    },
    klubname:"",
    klubinfo: {},
    klubid:null,
    isAuthenticated: false,
    token: '',
    orders:{},
    loadcount:0,
    checkoutresponse:{},

    email: '',
    isLoading: false,
    routeParam:"",

  },
  mutations: {
    initializeStore(state) {

      if (localStorage.getItem('klubid')) {
        
        state.klubid = localStorage.getItem('klubid')
       
      }
   
      if (localStorage.getItem('cart')) {
        
        state.cart = JSON.parse(localStorage.getItem('cart'))
       
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      } 
      if (localStorage.getItem('email')) {
        state.email = localStorage.getItem('email')
      }
    
    
    if (localStorage.getItem('klubinfo')) {

      state.klubinfo = JSON.parse(localStorage.getItem('klubinfo'))
      console.log(state.klubinfo)

  }
  if (localStorage.getItem('checkoutresponse')) {

    state.checkoutresponse = JSON.parse(localStorage.getItem('checkoutresponse'))

} 
 else {
      state.klubinfo = []

  } 


    
    },
    setRouteParam(state, param) {
      state.routeParam = param;
    },
    setCartKlub(state, klub) {

      
      state.cart.klubid = klub
   
  
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },

    
    addToCart(state, item) {
      const exists = state.cart.items.filter(i => i.jelo === item.jelo)
  
      if (exists.length) {
        exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
      } else {
        state.cart.items.push(item)
      }
  
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    addtorewards(state, item) {

      console.log(state.cart)

      item = item[0]
      if(!state.cart.rewards){
        state.cart.rewards = []
      }
      
      const exists = state.cart.rewards.filter(i => i[2] === item[2])
   
      
      if ( exists.length) {
        return;
      } else {
        state.cart.rewards.push(item)
      }
  
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
  
    removeFromCart(state, item) {
      console.log(item)
     
      const index = state.cart.items.findIndex(i => i.id === item.id);
      console.log(index)
  
      if(index !== -1){
        item =  state.cart.items[index];
  
        if(item.quantity > 1){
            item.quantity--; //remove one quantity
        }else{
          state.cart.items.splice(index, 1);
        }
      }
  
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    removereward(state, id) {

      console.log(state.cart.rewards)
      console.log(id)

      const index = state.cart.rewards.findIndex(i => i[2] === id)
      console.log(index)
    
      let item;
      if(index !== -1){
        item =  state.cart.rewards[index];
        state.cart.rewards.splice(index, 1);

    }
  
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },


    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
        state.token = token
        state.isAuthenticated = true
    },  
    setKlub(state, klub) {
      state.klubinfo = klub
      state.klubid = klub.id
   
  },  
  setCheckout(state, checkout) {
    state.checkoutresponse = checkout
 
},  

    removeToken(state) {
        state.token = ''
        state.isAuthenticated = false
    },
    clearCart(state) {
      console.log("emptyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy")
      state.cart = { items: [], rewards:[], klubid:state.cart.klubid }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    clearRewards(state) {
      console.log("emptyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy")
      state.cart.rewards = []

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
  },
  actions: {
  },
  modules: {
  }
})


