<template>

<Navigation :hidefooter="false" :showlogo="true">
   <template #body>

      <section class="sub-banner-sec w-100 float-left d-table position-relative">
         <img :src="this.$store.state.klubinfo.bg" class="section-background-image">
         <div class="overlay" ></div>
   
            <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con text-white text-center wow bounceInUp" data-wow-duration="1s">
               <h1 class="position-relative">About Us</h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb d-inline-block">
                    <li class="breadcrumb-item d-inline-block"><a class="text-white" @click="$router.push('/'+this.$store.state.klubinfo.ime)">Home</a></li>
                    <li class="breadcrumb-item active text-white d-inline-block" aria-current="page">About</li>
                    </ol>
                </nav>
            </div>
         </div>
      </div>
        

   </section>
   <!--banner-->
   <!--conference-sec-->
   <section class="conference-main-sec w-100 float-left padding-top padding-bottom position-relative">
      <div class="container">
         <div class="generic-title text-center wow bounceInUp" data-wow-duration="1s">
            <h2>Why Attend Our Events <i class="fa fa-question" style="color:orange"></i></h2>
            <p>{{this.$store.state.klubinfo.onama}}</p>
         </div>
       
      </div>

      <div class="generic-title text-center wow bounceInUp" data-wow-duration="1s">
            <h2 style="padding-top: 20px;">Contact us! &nbsp;<i class="fa fa-envelope" style="color:orange"></i></h2>
            <p>
{{klubdata.email}}<br>

<br></p>

<p>
{{klubdata.telefon}}
</p>
         </div>

   </section>

   </template>
</Navigation>

</template>


<script>
import axios from 'axios'
import Navigation from '../components/Navigation.vue'
export default {
  name: 'HomeView',
  components: {
   Navigation
  },
  data(){
   return{
   
      email:"",
      subscribe:false,
     
      klubdata:[]
   }
   
  },
  mounted(){


   
   console.log(this.klubdata)
   

   $(".containerloading").hide()

   const refresh = this.$root.getKlub(this.$route.params.klub)
   this.klubdata = this.$store.state.klubinfo

  },

  methods : {


   async makeNewsletter() {
      console.log(this.email)
   

   await axios
     .get('/newsletter/'+this.email)
     .then(res => {


      this.subscribe = true
      setTimeout(() => {
         this.subscribe = false
        console.log("Delayed action executed after 1 second");
      }, 4000); // 1000 milliseconds = 1 second
      
  

      
  
   
       
   
   
     })
     .catch(error => {
   
       console.log(error)
   
     })
   
   },
  }
}
</script>
