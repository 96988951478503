<template>

	<div id="notfound" >
		<div class="notfound" >
			<img src="../assets/images/yes.png" alt="" style="width:30%;padding-bottom: 20px;">
      <br>

     
        <span >
          <p v-if="order.totalbodovi && $store.state.isAuthenticated" style="font-weight: bold;font-size: 20px;color:var(--button-color);">{{ order.totalbodovi }} points</p>
            <p style="font-size:20px; color:gray;"><b>Thank you for your order</b></p><br>
            <p style="font-size:20px; color:gray;">Bartender will bring you ordered items soon</p><br>

            <div style="padding-bottom: 15px;" v-if="this.$store.state.isAuthenticated">
              <button  class="btn btn-light" style="border:none;border-radius: 25px;" @click="$router.push('/nightwise/user/')"><i class="fa fa-martini-glass" style="color:black;"></i> More Details</button>
    
            </div>
       
            <span>

              <p style="color:var(--button-color);font-weight: bold;">Order id: {{this.order.id}}</p>
    
            <p v-if="this.order.vrsta!='bar'">Order to table <b style="font-weight: bold;">{{ this.order.stol }}</b></p>

            <p v-if="this.order.placanje=='cash'">Pay to bartender by cash or card.</p>

              <p v-for="i in order.cart"><b style="font-weight: bold;">{{i.quantity}}x {{ i.jelo }}</b> {{i.price}}€</p>
        
              <hr>
              <div v-if="order.rewards && order.rewards.length">
                <h4 style="font-weight: bold;"><i class="fa fa-gift"></i> &nbsp; Rewards</h4>
                <p v-for="i in order.rewards">
                  <span v-if="i[4]">
                     {{ i[5] }} &nbsp;<b style="font-weight: bold;">{{i[1]}} points + {{ i[14] }}€</b>
                  </span>
            
                </p>
              </div>
              <p style="font-weight: bold;">Total to pay: {{this.order.total}}€</p>
            </span>
     
            <br>

            <p style="color:gray">Go back to menu:</p>

            <button  class="btn btn-light" style="border:none;border-radius: 25px;" @click="$router.push('/menu/'+this.$store.state.klubinfo.ime+'/'+this.table)"><i class="fa fa-arrow-left" style="color:black;"></i></button>

     

        </span>
		
        <br>
        

      
    
		</div>
	</div>


</template>


<script>
import axios from 'axios'
export default {
  name: 'Uspjeh',
  data(){
    return{

      club:"",
      table:"",
      order:"",
   
     
  
      
    }
  },

  computed: {


total() {
  
  let total = 0
 
  if (this.order && this.order.cart.length){
    let total = 0

  for (let i = 0; i < this.order.cart.length; i++) {

      total += this.order.cart[i].price * parseInt(this.order.cart[i].quantity)

  }

  return total.toFixed(2);
  }

  
  
  return total.toFixed(2);

},

  },

  mounted(){
    $(".containerloading").hide()
    this.table = this.$route.params.stol
    this.club = this.$route.params.club
    console.log(localStorage.getItem("orderdata"))
    this.order = JSON.parse(localStorage.getItem("orderdata"))
    console.log(this.order)

  


  },
  
  methods:{
  
    

  }
  

  
  
}
</script>
<style scoped>

#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
}

.notfound .notfound-404 h1>span {
  color: #00b7ff;
}

.notfound h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

.notfound .notfound-search {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: auto;
}

.notfound .notfound-search>input {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 50px;
  padding: 3px 65px 3px 30px;
  color: #151515;
  font-size: 16px;
  background: transparent;
  border: 2px solid #c5c5c5;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search>input:focus {
  border-color: #00b7ff;
}

.notfound .notfound-search>button {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.notfound .notfound-search>button>span {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: -3px;
}


@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}


</style>