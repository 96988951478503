<template>
<Navigation :showlogo="true" :hidefooter="false">
<template #body>



	<div class="container d-flex justify-content-center" style=" box-shadow: 10px 10px 20px 0px red(0, 0, 0, 0.5);background: rgba(237, 231, 225, 0.2);padding-top: 120px;">
		<div class="notfound" >
   
      <div class="message-box message-box-success" v-if="order && order.odobreno && !order.zavrseno">
  <i class="fa fa-check fa-2x"></i>
  <span class="message-text"><strong>Success, Song will be played</strong>{{this.$store.state.klubinfo.klub}}</span>
  <p style="padding-bottom: 5px;"><strong>{{ new Date(this.order.vrijeme).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}</strong> <strong>{{order.pjesma}}</strong></p>



</div>
<!--v-if="order && !order.odobreno && !order.odbijeno"-->
<div class="message-box message-box-info"  v-if="order && !order.odobreno && !order.odbijeno">
  <i class="fa fa-info-circle fa-2x"></i>
  <span class="message-text"><strong>Waiting for DJ approval</strong> {{this.$store.state.klubinfo.klub}}</span><br>
  <p style="padding-bottom: 5px;"><strong>{{ new Date(this.order.vrijeme).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}</strong> <strong>{{order.pjesma}}</strong></p>



</div>

<div class="message-box message-box-error" v-if="order && order.odbijeno">
  <i class="fa fa-ban fa-2x"></i>
  <span class="message-text"><strong>DJ rejected your choice</strong> {{this.$store.state.klubinfo.klub}}</span>
  <p style="padding-bottom: 5px;"><strong>{{ new Date(this.order.vrijeme).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}</strong> <strong>{{order.pjesma}}</strong></p>


</div>



<img src="../assets/images/music.png" alt="" style="width:30%;" v-if="pokazi">
      <br>

        <span >
            <br>
            <p style="font-size:20px; color:gray;"><b>Request a song from DJ</b></p>
            <p>You can only send one suggestion at time</p>
            <span v-if="!wait && !potvrda && !placeno">
            <input class="form-control" placeholder="Type song name" v-model="songform.song"><br>
            <button class="btn btn-success" style="background-color: #ed1650 ;border: #ed1650 ;" @click="sendsong">Send</button>
<br><br>
           
        </span>


  

        <p style="color:gray">Go back:</p>

<button  class="btn btn-light" style="border:none;border-radius: 25px;" @click="$router.push('/'+this.$store.state.klubinfo.ime)"><i class="fa fa-arrow-left" style="color:black;"></i></button>
<br>
        </span>

      
		
        <br>
        

      
    
		</div>
	</div>
<div style="height: 200px;"></div>
</template></Navigation>


</template>
<style scoped>
.alter1-header {
    position: relative;
    background-image: linear-gradient(160deg, white 0, var(--footercolor) 70%, var(--footercolor) 110%);

    height: 10px;
}
</style>

<script>
import Navigation from '../components/Navigation.vue'
import axios from 'axios'
export default {
  name: 'Uspjeh',
  components: {
   Navigation

  },
  data(){
    return{
      pokazi:false,
      order:null,
        id:null,
        placeno:false,
      wait:false,
      potvrda:false,
      club:"",
      table:"",
      song:"",

      songform:{
        song:"",
        club:"",
        table:"",
        auth:false,
        token:""
      },
      intervalId:null,
   
     
  
      
    }
  },

  beforeRouteLeave(to, from, next) {
    clearInterval(this.intervalId);
    next();
  },
  
  mounted(){
    this.getStatus()


    $(".containerloading").hide()
    this.table = this.$route.params.stol
    this.club = this.$route.params.klub
    
    if(this.$store.state.isAuthenticated){
      this.songform.auth = true
      this.songform.token = this.$store.state.token
    }
    else{
      this.songform.auth=false
    }

    this.songform.club = this.$store.state.klubid
    this.songform.table = this.table


  },
  methods:{

    async getStatus() {


await axios
  .get('/guest/musicstatus/'+this.$store.state.klubid)
  .then(res => {
    this.pokazi = false
  if(res.data.id){
    this.order = res.data
    if(!this.order.odobreno && !this.order.odbijeno){
      this.intervalId = setInterval(() => {
        this.Provjeri();
      }, 5000); // 5000 milliseconds = 5 seconds

    }
  }
  else{
    this.pokazi = true
  }

  })
  .catch(error => {
    if(error.response.status==401){
      this.$root.toastnoti("Success!", 'Please log in to order music', "greska")
      localStorage.removeItem("token")
      localStorage.removeItem("email")
      this.$router.push('/Steel/login/')
    }
    this.pokazi = true
  })

},

    stopInterval() {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },

    sendsong(){
        $(".containerloading").show()
axios.post('/klub/ordersong/', this.songform)
    .then((res) => {
        
      
        $(".containerloading").hide()
        this.$root.toastnoti("Success!", 'Song ordered, waiting for DJ approval', "uspjeh")

        this.id = res.data

        let now = new Date();


        this.order = {'id':this.id, 'ime':this.$store.state.klubinfo.ime, 'vrijeme':now, 'pjesma':this.songform.song, 'odobreno':false, 'placeno':false, 'zavrseno':false, 'odbijeno':false}

        this.intervalId = setInterval(() => {
        this.Provjeri();
      }, 5000); // 5000 milliseconds = 5 seconds

            
      })
    .catch((error) => {
      console.log(error)    
     
    })

},

async Provjeri() {



  await axios
  .get('/guest/music/'+this.order.id)
  .then(res => {

    if(res.data.odobreno){
      this.order.odobreno = true
      this.$root.toastnoti("Success!", 'DJ approved your song choice, Thanks', "uspjeh")
      this.stopInterval()
      this.pokazi = false
    }
    if(res.data.odbijeno){
      this.order.odbijeno = true
      this.$root.toastnoti("Success!", 'DJ did not approve your choice.', "greska")
      this.stopInterval()
      this.pokazi = false
    }

  })
  .catch(error => {

  })


},

async Plati() {
    console.log(this.id)

await axios
  .get('/klub/plati/'+this.order.id)
  .then(res => {
    this.order.placeno = true
    this.pokazi = false
    this.$root.toastnoti("Success!", 'Payment Success, DJ will soon play the song you chose.', "uspjeh")

  
  })
  .catch(error => {

  })

},



  }
  

  
  
}
</script>
<style scoped>

.message-box {
	 width:100%;
	 border-radius: 6px;
	 margin: 20px auto;
	 padding: auto 0;
	 position: relative;
}
 .message-box i {
	 vertical-align: middle;
	 padding: 20px 5px 20px 0px;
}
 .message-box i.exit-button {
	 float: right;
	 opacity: 0.4;
}
 .message-box i.exit-button:hover {
	 opacity: 0.8;
}
 .message-text {
	 vertical-align: middle;
   padding-right: 20px;

}
 .message-box-info {
	 background-color: #cde8f6;
	 border: #2697d1 2px solid;
	 color: #447eaf;
}
 .message-box-warn {
	 background-color: #f8f4d5;
	 border: #e9dd7e 2px solid;
	 color: #96722e;
}
 .message-box-error {
	 background-color: #ecc8c5;
	 border: #d37f78 2px solid;
	 color: #b83c37;
}
 .message-box-success {
	 background-color: #ddf3d5;
	 border: #9ddc86 2px solid;
	 color: #597151;
}

#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  margin-top: 20%;
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #262626;
  text-transform: uppercase;
}

.notfound .notfound-404 h1>span {
  color: #00b7ff;
}

.notfound h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

.notfound .notfound-search {
  position: relative;
  max-width: 320px;
  width: 100%;
  margin: auto;
}

.notfound .notfound-search>input {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  height: 50px;
  padding: 3px 65px 3px 30px;
  color: #151515;
  font-size: 16px;
  background: transparent;
  border: 2px solid #c5c5c5;
  border-radius: 40px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound .notfound-search>input:focus {
  border-color: #00b7ff;
}

.notfound .notfound-search>button {
  position: absolute;
  right: 15px;
  top: 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.notfound .notfound-search>button>span {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
      -ms-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  margin-left: -3px;
}


@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}


</style>