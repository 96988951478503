<template>
<Navigation :showlogo="true" :hidefooter="true">
    <template #body >
        <!--navbar-->
       <!--banner-->

       <!--banner-->
        
       <div class="generic-button white-btn d-inline-block wow bounceInUp d-flex justify-content-center buttonloyalty" data-wow-duration="1s" v-if="this.$store.state.isAuthenticated" @click="otvorimodal(true)">
                 
                 <a style="color:white;background: #494B4E !important;cursor: pointer;">{{bodovi}} NightWise points <i class="fa fa-moon" style="color:#494B4E;background: white !important;"></i></a>
              </div>

       <div class="cards">
      
        
        
        <input type="text" class="form-control wow bounceInUp" style="border-radius: 10px;" placeholder="Try searching for your favorite drink ..." data-wow-duration="1s" v-model="filterval" v-on:input="filterrewards()">
        <p v-if="ponude && !ponude.length">No offers for now </p>
        <article class="plan [ card ]" v-for="i in ponude" v-show="i[15]>0">

    <div class="inner" v-if="i[4]">

        <span class="pricing">
            <span>
<small>{{ i[1] }} <i class="fa fa-moon"></i></small>
            </span>
        </span>
        <h2 class="title"><i class="fa fa-martini-glass"></i> Drink Discount</h2>
        <p class="info"><b>{{i[3]}}%</b> Discount on {{i[5]}} <br><s>{{i[9]}}€</s> {{(i[9]*((100-i[3])/100)).toFixed(1)}}€</p>

        <button class="buttonoffer" :class="provjeribodove(i[1])" @click="iskoristibodove(i[1], i[2])">
            Claim Offer
        </button>
    </div>



    <div class="inner ticketplan" v-if="!i[4]" >

        <span class="pricing">
            <span>
<small>{{i[1]}}</small>
            </span>
        </span>
        <h2 class="title"><i class="fa-solid fa-ticket"></i> Ticket Discount</h2>
        <p class="info"><b>{{i[3]}}%</b> Discount on {{i[11]}}<br> <s style="font-weight: bold;">{{i[10]}}€</s> {{(i[10]*((100-i[3])/100)).toFixed(1)}}€</p>
        
        <button class="buttonoffer" :class="provjeribodove(i[1])" @click="openticketmodal(i[1], i[2])">
            Claim Offer
        </button>
    </div>
</article>

</div>
    <section class="menu" >
        <div class="container">
        <div class="row">
        <div class="col-md-12">
        <div class="page-header wow fadeInDown">
    
    <br>
        </div>
        </div>
        </div>
        <div class="food-menu wow fadeInUp">
        <div class="row">
        <div class="col-md-12"> 
        <div class="menu-tags">
        <br>

   
    
        </div>
        </div>
        </div>
  
 
        </div>
        </div>
        </section>


        <div class="modal fade" id="rezervirajModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel"></h4>
            <i class="fa fa-circle-xmark" style="color:#ed1650;font-size: 30px;cursor: pointer;"  @click="this.zatvoriform('rezervirajModal')" ></i>
  
          </div>

          <div class="modal-body">
            <form style="padding:10px">		

				<b style="font-weight: bold;font-size: 50px;color:#ed1650" v-if="bodoviform!=0">{{ this.bodoviform }}</b><b v-else style="font-size: 50px;color:#ed1650"><i class="fa fa-plus"></i></b><i class="fa fa-coins" style="font-size: 40px;padding-left: 10px;color:#ed1650"></i>
<br>
<span v-if="bodoviform!=0">
    <p style="color:gray;" > </p>

</span>
				
         <br>
         <p style="color:gray;">Add this offer to your cart. <br>It will be used when you start the order.</p>
        <br><br>
        <div style="padding-bottom:10px;padding-right: 5px;" class="generic-button red-btn d-inline-block"  >
              <a style="color:white;cursor:pointer" @click="addtocart(idponude)"> Add <i class="fa fa-plus"></i></a>
              
           </div> <br><br>
           <a @click="gotocart()" class="btn-getstarted scrollto position-relative" id="podsjetnik" style="color:orange;font-size: 20px;">
            <i class="fa fa-shopping-cart"></i>
            <span style="color:black;padding-left: 5px;">Go to cart</span>
          
    
    </a>
          </form>
          </div>
          <div class="modal-footer d-flex justify-content-center">


  
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="bodoviModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel"></h4>
            <i class="fa fa-circle-xmark" style="color:#ed1650;font-size: 30px;cursor: pointer;"  @click="this.zatvoriform('bodoviModal')" ></i>
  
          </div>

          <div class="modal-body">
            <form style="padding:10px">		

			<b  style="font-size: 50px;color:#ed1650"><i class="fa fa-plus"></i></b><i class="fa fa-coins" style="font-size: 40px;padding-left: 10px;color:#ed1650"></i>
<br><br>
<span>
    <p style="color:gray;" > To get more points buy tickets for events or order drinks through NightWise</p>

</span>
			
          

          </form>
          </div>
          <div class="modal-footer d-flex justify-content-center">


  
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="ticketModal" tabindex="-1" role="dialog" aria-labelledby="deleteLabel" aria-hidden="true" style="padding-top:50px;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title modelheading" id="deleteLabel"></h4>
            <i class="fa fa-circle-xmark" style="color:#ed1650;font-size: 30px;cursor: pointer;"  @click="this.zatvoriform('ticketModal')" ></i>
  
          </div>

          <div class="modal-body">
            <form style="padding:10px">		

				<b style="font-weight: bold;font-size: 50px;color:#ed1650" v-if="bodoviform!=0">{{ this.bodoviform }}</b><b v-else style="font-size: 50px;color:#ed1650"><i class="fa fa-plus"></i></b><i class="fa fa-coins" style="font-size: 40px;padding-left: 10px;color:#ed1650"></i>
<br>

         <br>
         <p style="color:gray;">Get the ticket for {{this.ticketform.discount}}% Discount<br><b><s>{{ ticketform.cijena }}</s> {{ (ticketform.cijena*((100-ticketform.discount)/100)).toFixed(1)}}€</b></p>
         <p>{{ ticketform.ticket }} Ticket <b style="font-weight: bold;">{{ ticketform.event }}</b></p>
         <p><b style="font-weight: bold;">Ticket Description: </b> {{ ticketform.opis }}</p>
        <br><br>
        
        <button class="buttonoffer buttonoffer2" type="button" @click="redirectevent()">
            Event Details
        </button> &nbsp;

        <button class="buttonoffer" type="button" @click="buyticketsoffer()">
            Buy Ticket
        </button>
    
          </form>
          </div>
          <div class="modal-footer d-flex justify-content-center" style="padding-top: 100px;">


  
          </div>
        </div>
      </div>
    </div>


<div style="height:50px"></div>
    </template>
</Navigation>

       

    
    </template>
    
    
    <script>
import QrcodeVue from 'qrcode.vue'
import Navigation from '../components/Navigation.vue'
    import axios from 'axios'
    export default {
      name: 'Restaurant',
      components: {

        QrcodeVue,
        Navigation,
      },
    
      data(){
        return{
           bodovi:0,
           idponude:null,
           ponude:null,
           ponude2:null,
           qrbodovi:'https://example.com',
           size: 200,
           bodoviform:0,
           email:"",
           emailsabodovima:"",
           emailsabodovimaqr:"",
           emailqr:"",
           bgstylevar:"",

           ticketform:{
            klubid:this.$store.state.klubid,
            discount:null,
            cijena:null,
            ticket:null,
            event:null,
            id:null,
            eventid:null,
           }
        }
      },
    

      mounted(){
        this.$root.getKlub(this.$route.params.klub)
        if(!this.$store.state.cart.klubid || (this.$store.state.cart.klubid != this.$store.state.klubid) ){

this.$store.commit('clearCart')
this.$store.commit('setCartKlub', this.$store.state.klubid)


}
        $(".containerloading").show()
        const data = this.$store.state.klubinfo
        this.bgstylevar = `url(${data.bg}) no-repeat center bottom`

        this.getBodovi()
        this.getPonude()

    
      },

     
    
      methods:{
        filterrewards(){
            
               var inputtext = this.filterval
               if(inputtext.trim() == ""){
                  this.ponude = this.ponude2
               }
               else{
                  const searchRegex = new RegExp(this.filterval, 'i');
            

            this.ponude = this.ponude2.filter(item => {
              return (
                searchRegex.test(item[5])
   
            
              );
            });
               }
            

            },

        buyticketsoffer(){

            $(".containerloading").show()
            console.log("tickets")
    axios.post('/stripe/ticketoffer/', this.ticketform)
    .then((res) => {

      
      $(".containerloading").hide()
      $("#ticketModal").modal("hide")
      
        localStorage.setItem("orderdata", JSON.stringify(res.data.order))
        const secretresponse = {'clientSecret':res.data.clientSecret}
        localStorage.setItem("checkoutresponse", JSON.stringify(secretresponse))
        this.$store.commit('setCheckout', secretresponse)

  
      this.$router.push('/stripe/checkout/')
  

            
      })
    .catch((error) => {
      $(".containerloading").hide()
      this.$root.toastnoti("Error", error.response.data, "greska")

     
    })
},  

        redirectevent(){
            $("#ticketModal").modal("hide")
            this.$router.push('/details/'+this.$store.state.klubinfo.ime+'/'+this.ticketform.eventid)
        },
        openticketmodal(bodovi, id){
      
            if(bodovi>this.bodovi){
                this.$root.toastnoti("Error", "Not enoguh points", "greska")
                return;
            }

                let ticket = this.ponude.filter(i => i[2] === id);
                ticket = ticket[0]
                this.ticketform.discount = ticket[3]
                this.ticketform.cijena = ticket[10]
                this.ticketform.ticket = ticket[6]
                this.ticketform.event = ticket[11]
                this.ticketform.opis = ticket[12]
                this.ticketform.id = id
                this.ticketform.eventid = ticket[13]

                this.bodoviform = bodovi
                this.idponude = id

                $("#ticketModal").modal("show")
            
            

            

        },
        provjeribodove(bod){
            if(bod<this.bodovi){
                return ''
            }
            else{
                return 'disabled'
            }
        },
        gotocart(){
            $("#rezervirajModal").modal("hide")
            this.$router.push('/cart/'+this.$store.state.klubinfo.ime+'/nightwise')
            
        },
        addtocart(id){
            console.log("id je ", id)
            let reward = this.ponude.filter(i => i[2] === id);
            console.log(reward)
            this.$store.commit('addtorewards', reward)
            this.$root.toastnoti("Success!", "Offer added to cart", "info")
        },
        qrkodkrug(bodovi){
            var returnclass = 'fa fa-plus circle-icon '
            if(this.bodovi>=bodovi){
                return returnclass + "greencircle"
            }
           else{
            return returnclass + "redcircle"
           }
        },

        iskoristibodove(bodovi, id){
            if(bodovi>this.bodovi){
                this.$root.toastnoti("Error", "Not enoguh points", "greska")
                return;
            }
                this.bodoviform = bodovi
                this.idponude = id

                $("#rezervirajModal").modal("show")
            
            

            

        },

        zatvoriform(modalname){

$('#' + modalname + ' ' +  '.modal-content').removeClass('animate-bottom').addClass('animate-down');
 $("#"+modalname).modal('hide');   



},

otvorimodal(dodaj){
    if(dodaj){
        
        this.bodoviform = 0
    }
        this.emailqr='0/'+this.email
    
      $("#bodoviModal").modal("show")

   },



  
    
         async getBodovi() {
    
          await axios
            .get('/loyalty/mojibodovi/')
            .then(response => {
             this.bodovi = response.data.bodovi
             
      
    
            $(".containerloading").hide()
              
    
            
            })
            .catch(error => {
                    console.log(error)
                if(error.response.status == 401){
              
                    if(this.$store.state.klubinfo.ime){
                this.$router.push('/account/' + this.$store.state.klubinfo.ime + '/login/')
                }
                else{
                    this.$router.push('/account/nightwise/login/')
                }
                }
            })
    
        },

        async getPonude() {
    
    await axios
      .get('/loyalty/ponude/'+this.$store.state.klubid)
      .then(response => {
       this.ponude = response.data.ponude
       this.ponude2 = response.data.ponude
       console.log(this.ponude)
   

      $(".containerloading").hide()
        

      
      })
      .catch(error => {
  console.log(error)
      })

  },
    
    
      },
    
    }
    </script>
<style>
 :root {
	 --c-white: #fff;
	 --c-black: #000;
	 --c-ash: #eaeef6;
	 --c-charcoal: #a0a0a0;
	 --c-void: #141b22;
	 --c-fair-pink: #ffedec;
	 --c-apricot: #fbc8be;
	 --c-coffee: #754d42;
	 --c-del-rio: #917072;
	 --c-java: #1fcac5;
	 --c-titan-white: #f1eeff;
	 --c-cold-purple: #a69fd6;
	 --c-indigo: #6558d3;
	 --c-governor: #4133b7;
}

</style>
<style scoped>
.buttonoffer2 {
    background-color: var(--c-cold-purple) !important;
    padding-left: 10px;
    padding-right: 10px;
}
.buttonloyalty {
    margin-top: 100px;
}

/* Media query for desktop and larger devices */
@media (min-width: 768px) { /* Adjust the min-width as per your requirement */
    .buttonloyalty {
    margin-top: 150px;
}
}

.disabled{
    opacity: 0.5;
}
.cards {
	 display: flex;
	 flex-wrap: wrap;
	 align-items: flex-start;
	 flex-wrap: wrap;
	 justify-content: center;
	 gap: 2.5rem;
	 width: 90%;
	 max-width: 1000px;
	 margin: 5vh auto;
}
 .card {
	 border-radius: 16px;
	 box-shadow: 0 30px 30px -25px rgba(65, 51, 183, .25);
	 width: 340px;
}
 .information {
	 background-color: var(--c-white);
	 padding: 1.5rem;
}
 .information .tag {
	 display: inline-block;
	 background-color: var(--c-titan-white);
	 color: var(--c-indigo);
	 font-weight: 600;
	 font-size: 0.875rem;
	 padding: 0.5em 0.75em;
	 line-height: 1;
	 border-radius: 6px;
}
 .information .tag + * {
	 margin-top: 1rem;
}
 .information .title {
	 font-size: 1.5rem;
	 color: var(--c-void);
	 line-height: 1.25;
}
 .information .title + * {
	 margin-top: 1rem;
}
 .information .info {
	 color: var(--c-charcoal);
}
 .information .info + * {
	 margin-top: 1.25rem;
}
 .information .button {
	 font: inherit;
	 line-height: 1;
	 background-color: var(--c-white);
	 border: 2px solid var(--c-indigo);
	 color: var(--c-indigo);
	 padding: 0.5em 1em;
	 border-radius: 6px;
	 font-weight: 500;
	 display: inline-flex;
	 align-items: center;
	 justify-content: space-between;
	 gap: 0.5rem;
}
 .information .button:hover, .information .button:focus {
	 background-color: var(--c-indigo);
	 color: var(--c-white);
}
 .information .details {
	 display: flex;
	 gap: 1rem;
}
 .information .details div {
	 padding: 0.75em 1em;
	 background-color: var(--c-titan-white);
	 border-radius: 8px;
	 display: flex;
	 flex-direction: column-reverse;
	 gap: 0.125em;
	 flex-basis: 50%;
}
 .information .details dt {
	 font-size: 0.875rem;
	 color: var(--c-cold-purple);
}
 .information .details dd {
	 color: var(--c-indigo);
	 font-weight: 600;
	 font-size: 1.25rem;
}
 .plan {
	 padding: 10px;
	 background-color: var(--c-white);
	 color: var(--c-del-rio);
}
 .plan strong {
	 font-weight: 600;
	 color: var(--c-coffee);
}
 .plan .inner {
	 padding: 20px;
	 padding-top: 40px;
	 background-color: var(--c-fair-pink);
	 border-radius: 12px;
	 position: relative;
	 overflow: hidden;
}
.ticketplan {
    background-color: var(--c-ash) !important;
}
 .plan .pricing {
	 position: absolute;
	 top: 0;
	 right: 0;
	 background-color: var(--c-apricot);
	 border-radius: 99em 0 0 99em;
	 display: flex;
	 align-items: center;
	 padding: 0.625em 0.75em;
	 font-size: 1.25rem;
	 font-weight: 600;
	 color: var(--c-coffee);
}
 .plan .pricing small {
	 color: var(--c-del-rio);
	 font-size: 0.75em;
	 margin-left: 0.25em;
}
 .plan .title {
	 font-weight: 600;
	 font-size: 1.25rem;
	 color: var(--c-coffee);
}
 .plan .title + * {
	 margin-top: 0.75rem;
}
 .plan .info + * {
	 margin-top: 1rem;
}
 .plan .features {
	 display: flex;
	 flex-direction: column;
}
 .plan .features li {
	 display: flex;
	 align-items: center;
	 gap: 0.5rem;
}
 .plan .features li + * {
	 margin-top: 0.75rem;
}
 .plan .features .icon {
	 background-color: var(--c-java);
	 display: inline-flex;
	 align-items: center;
	 justify-content: center;
	 color: var(--c-white);
	 border-radius: 50%;
	 width: 20px;
	 height: 20px;
}
 .plan .features .icon svg {
	 width: 14px;
	 height: 14px;
}
 .plan .features + * {
	 margin-top: 1.25rem;
}
 
.buttonoffer {
	 font: inherit;
	 background-color: var(--c-indigo);
	 border-radius: 6px;
	 color: var(--c-white);
	 font-weight: 500;
	 font-size: 1.125rem;
	 width: 100%;
	 border: 0;
	 padding: 1em;
}
 .plan button:hover, .plan button:focus {
	 background-color: var(--c-governor);
}
</style>
<style scoped>

.bannertest{
    border-top-left-radius: 0;     /* Top left corner remains square */
    border-top-right-radius: 0;    /* Top right corner remains square */
    border-bottom-left-radius: 50%;  /* Bottom left corner is oval */
    border-bottom-right-radius: 50%; /* Bottom right corner is oval */
    height: 200px;
}
.modal-dialog{
   top:0% !important;
   text-align: center;
}

.modal-content{
  max-height:75vh !important;

}

@media only screen and (min-width: 600px) {
  .svgklub {
   
   
   width:50%;
   
  
  }
}
</style>

    <style scoped>
    
    .circle-icon {
            display: inline-block;
            width: 50px; /* Set the width and height to make it a circle */
            height: 50px;
            line-height: 50px; /* Center the icon vertically */
            text-align: center; /* Center the icon horizontally */
            border-radius: 50%; /* Make it a circle by setting border-radius to 50% */
        
            color: #fff; /* Set the icon color */
        }
    .redcircle{
        background-color: var(--button-color); /* Add a background color if needed */
    }
    .greencircle{
        background-color: green /* Add a background color if needed */
    }
    #dodaj:hover {
    color: #28b9d4;
    } 
    
    .hoverbodovi:hover{
        color:var(--button-color) !important;
    }
    .price{
        color:#858fa1!important;
    }
    @media (max-width: 767px){
    h1, .h1 {
        font-size: 32px;
    }
    
    }
    
    .imgjelo {
      width: 100%;
      height: 300px;
    }
    
    .img--coverjelo {
      object-fit: cover;
    }
    
    .img--containjelo {
      object-fit: contain;
    }
    
    
    .JELO {
      opacity: 1;
      transition: opacity 1s; 
    }
    
    .JELO.fade {
      opacity: 0;
    }
    
    .menup {
        font-family: josefin sans,sans-serif;
        color: #828281;
        font-size: 22px;
    }
    
    
    .testh4{
        color:#848b99 !important;
        font-size:120%;
        font-family: raleway, sans-serif;
    
        color: #4b4741;
        text-align: left;
        margin: 5px 5px;
    
        padding-right: 10px;
        display: inline;
        background: #fff;
        font-weight: 800
    }
    
    .small, small {
        font-size: 65%;
        font-family: josefin sans,sans-serif;
        font-weight: 700;
        display: block!important;
        text-transform: none;
        text-align: center;
        margin: 10px 0 15px;
    }
    .testh1{
        font-family: raleway, sans-serif;
        text-transform: uppercase;
        color: var(--color-primary);
        text-align: center;
        font-weight: 800
    }
    
    .menu {
        padding-bottom: 30px
    }
    .space60 {
        margin-bottom: 60px
    }
    .menu-tags {
        text-align: center;
        margin-bottom: 20px
    }
    .menu-tags2, .menu-tags3, .menu-tags4 {
        text-align: center;
        margin-bottom: 50px;
        margin-top: 20px
    }
    .menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
        font-family: raleway, sans-serif;
        font-size: 18px;
        color: #828281;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 20px;
        padding-bottom: 2px;
        padding-top: 5px;
        cursor: pointer;
        transition: all .5s ease;
        border-bottom: 3px solid transparent
    }
    .menu-tags .tagsort-active, .menu-tags2 .tagsort2-active, .menu-tags3 .tagsort3-active, .menu-tags4 .tagsort4-active {
        border-bottom: 3px solid var(--button-color);
        color: #4b4741;
    
    }
    .menu .food-menu .menu-item, .menu .food-menu .menu-item3 {
        overflow: hidden;
        margin: 15px 0;
        padding-left: 10px;
        border-left: 3px solid #fff
    }
    .menu .food-menu .menu-item.featured {
        border-left: 3px solid #f9c56a
    }
    .menu .food-menu .menu-item .dotted-bg, .menu .food-menu .menu-item3 .dotted-bg {
        border-top: dotted 2px #ccc;
        left: 0;
        top: 15px;
        width: 100%;
        z-index: -1
    }
    .menu .food-menu .menu-item .menu-wrapper, .menu .food-menu .menu-item3 .menu-wrapper {
        position: relative;
        z-index: 1
    }
    .menu .food-menu .menu-item h4 class="testh4", .menu .food-menu .menu-item3 h4 class="testh4" {
        text-align: left;
        margin: 5px 0;
        float: left;
        padding-right: 10px;
        display: inline;
        background: #fff
    }
    .menu .food-menu .menu-item p, .menu .food-menu .menu-item3 p {
        font-size: 18px
    }
    .menu .food-menu .menu-item .price, .menu .food-menu .menu-item3 .price {
        float: right;
        padding-left: 10px;
        line-height: 19.8px;
        margin: 5px 0;
        background: #fff none repeat scroll 0% 0%;
        font-family: Josefin Sans;
        font-size: 22px;
        font-weight: 700
    }
    .menu .food-menu .menu-item-active {
        border-left: 3px solid #f9c56a
    }
    .menu .food-menu .menu-btn {
        text-align: center;
        padding: 20px 0
    }
    
    .menu-more, .menu-more:hover {
        width: 55px;
        height: 55px;
        -webkit-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
        -moz-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
        border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
        background-color: #f9c56a;
        -webkit-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
        -moz-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
        box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
        text-shadow: 0 5px 5px #c1913e;
        border: solid 1px #f2ba57;
        color: #fff;
        line-height: 0;
        font-weight: 300;
        font-size: 65px;
        text-align: center;
        display: table;
        position: absolute;
        z-index: 48;
        bottom: -27.5px;
        right: 45px;
        font-family: Raleway;
        padding-top: 27px;
        text-decoration: none
    }
    .menu2-overlay {
        background: rgba(19, 19, 19, .7);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transition: .4s
    }
    .menu-info:hover .menu2-overlay {
        opacity: 1;
        visibility: visible;
        transition: .4s
    }
    .menu2-overlay h4 class="testh4" {
        font-size: 19px;
        color: #fff;
        margin: 46px 0 5px
    }
    .menu2-overlay p {
        color: #fff;
        font-size: 19px;
        line-height: 24px
    }
    .menu2-overlay .price {
        font-family: Josefin Sans;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        border-top: 1px solid #3e3d3b;
        width: 100%;
        display: table;
        margin: 17px 0 0;
        padding-top: 17px
    }
    .menu-item3 img {
        width: 83px;
        float: left
    }
    .menu-item3 .menu-wrapper {
        margin-left: 115px
    }
    .menu-items3 p {
        float: left
    }
    .menu-items4 {
        margin-bottom: 75px
    }
    .menu-item4 {
        margin-bottom: 30px
    }
    .menu-item4 a {
        text-decoration: none !important
    }
    .menu4-overlay {
        width: 100%;
        background-color: #fff;
        box-shadow: 0 5px 5px #e2e2e2;
        margin-bottom: 0;
        margin-top: -10px;
        text-align: center;
        text-decoration: none !important;
        display: table
    }
    .menu4-overlay h4 class="testh4" {
        font-size: 19px;
        color: #4b4741;
        margin: 46px 0 15px
    }
    .menu4-overlay p {
        color: #4b4741;
        font-size: 18px;
        line-height: 24px
    }
    .menu4-overlay .price {
        font-family: Josefin Sans;
        font-size: 36px;
        font-weight: 700;
        text-transform: uppercase;
        color: #e7ad48;
        width: 100%;
        display: table;
        margin: 0;
        padding: 13px 0 45px
    }
    
    @media(max-width:992px) {
        .list {
            padding-left: 0;
            margin-bottom: 0
        }
        .navbar .navbar-nav>li>a {
            padding: 9px 15px
        }
        .subscribe .btn-default {
            padding: 0 15px;
            height: 46px;
            border: 1px solid #f2ba57;
            margin-left: 0 px;
            margin-top: 0
        }
        .team-staff {
            border-bottom: 1px solid #dcdddd;
            padding-bottom: 30px;
            margin-bottom: 30px
        }
        .contact-social {
            margin-bottom: 60px
        }
        .menu-item2 {
            margin-bottom: 50px;
            width: 50%
        }
        .menu .food-menu .menu-item3 h4 class="testh4" {
            font-size: 14px
        }
        .menu-item4 {
            width: 50%
        }
        .rc-info {
            margin-left: 0
        }
        .recipie-content img {
            float: none;
            width: 100%;
            margin-bottom: 25px
        }
        .recipie-content hr {
            margin: 20px 0 50px
        }
        .rc-info {
            margin-bottom: 30px
        }
    }
    @media(max-width:767px) {
        .reservation .reservation-form .reservation-btn a {
            padding: 15px 14px;
            font-size: 15px
        }
        .table-responsive {
            padding: 10px 19px
        }
        .fp-content img {
            width: 100%
        }
        .recipie-content hr {
            margin: 20px 0 50px
        }
        .rc-info {
            margin-bottom: 30px
        }
        .menu-item2 {
            width: 100%
        }
        .menu-item2 img, .menu-item4 img {
            min-width: 100%
        }
        .post-date {
            margin: 0 0 30px;
            float: none
        }
        article h4 class="testh4" {
            margin: 40px 0 10px
        }
        h1, .h1 {
            font-size: 32px
        }
        .subscribe .btn-default {
            paddwing: 0 15px;
            height: 46px;
            border: 1px solid #f2ba57;
            margin-left: 0 px;
            margin-top: 0
        }
        .subscribe input {
            width: auto !important
        }
        .trusted-quote .quote-body {
            font-size: 19px
        }
        .trusted-quote .quote-author {
            font-size: 18px
        }
        .menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
            font-size: 15px;
            margin: 0 8px 4px;
            display: inline-block
        }
        .page_header h2 {
            font-size: 61px
        }
        .menu .food-menu .menu-item3 h4 class="testh4" {
            font-size: 14px
        }
        .menu-item4 {
            width: 100%
        }
    }
    @media(max-width:480px) {
        .reservation .reservation-form {
            padding: 10px
        }
        .special-slider .slider-content .btn {
            margin: 17px 5px 20px 0;
            font-weight: 700;
            font-size: 10px;
            letter-spacing: normal;
            padding: 10px 20px;
            float: left
        }
        .element-tab .nav-tabs>li>a {
            color: #4b4741;
            font-size: 12px;
            font-family: Raleway;
            padding: 19px 14px
        }
        .cart-table tbody tr td a {
            font-size: 11px
        }
        .cart-table {
            font-size: 8px
        }
        .cart-table img {
            max-width: 50px;
            height: auto
        }
        .menu .food-menu .menu-item3 .price {
            
            float: none;
            padding-left: 0;
            margin: 6px 0 11px;
            font-size: 22px;
            display: table;
            padding-top: 10px
        }
        .contact-info p {
            font-size: 15px
        }
        .menu2-overlay h4 class="testh4" {
            font-size: 17px;
            margin: 22px 0 5px
        }
        .table>tbody>tr>td {
            padding: 8px 2px
        }
        .shop-grid select {
            float: none;
            display: block;
            margin-bottom: 15px
        }
        .sg-list {
            margin-left: -12px
        }
    }
    
    .menu-info {
        position: relative;
        overflow: hidden
    }
    .menu-item4 img {
        transform: scale(1);
        transition: .4s;
        opacity: 1
    }
    .menu-item4:hover img {
        transform: scale(1.1);
        transition: .4s;
        opacity: .9
    }
    .menu4-overlay {
        position: relative;
        z-index: 444
    }
    .menu-item2 img {
        transform: scale(1);
        transition: .4s;
        opacity: 1
    }
    .menu-item2:hover img {
        transform: scale(1.1);
        transition: .4s;
        opacity: .9
    }
    
    
    </style>
    
    
    <style>
</style>