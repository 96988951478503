<template>
<Navigation :showlogo="true" :hidefooter="false">
<template #body>
     <!--navbar-->
   <!--banner-->
   <section class="sub-banner-sec w-100 float-left d-table position-relative">
    <img :src="this.$store.state.klubinfo.bg" class="section-background-image">
    <div class="overlay" ></div>

      <div class="d-table-cell align-middle content">
         <div class="container">
            <div class="banner-inner-con text-white text-center wow bounceInUp" data-wow-duration="1s">
               <h1 class="position-relative" style="margin-top: 40px;">Menu<br><span v-if="this.table!=0  && this.table!='nightwise' ">Table {{ table }}</span></h1>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb d-inline-block">
                        <li class="breadcrumb-item d-inline-block"><a class="text-white" @click="$router.push('/'+$store.state.klubinfo.ime)">Home</a></li>
                    <li class="breadcrumb-item active text-white d-inline-block" aria-current="page" @click="$router.push('/menu/'+$store.state.klubinfo.ime+'/'+table)">Menu</li>
                    <li class="breadcrumb-item d-inline-block"><a class="text-white" @click="$router.push('/'+$store.state.klubinfo.ime)">{{$store.state.klubinfo.ime}}</a></li>

                    
                    </ol>
                </nav>
         

            </div>
         </div>
      </div>
   </section>
   <!--banner-->

    
<section class="menu">
    <div class="container">
    <div class="row">
    <div class="col-md-12">
    <div class="page-header wow fadeInDown">

<br>
    </div>
    </div>
    </div>
    <div class="food-menu wow fadeInUp">
    <div class="row">
    <div class="col-md-12"> 
    <div class="menu-tags">
        <p v-if="!this.$store.state.email">Log in or Register to collect loyalty points.</p>
        <p v-if="this.$store.state.email">You will earn  <b style="font-weight: bold;"> NightWise</b> loyalty points </p>
        <div class="generic-button white-btn d-inline-block wow bounceInUp" data-wow-duration="1s" style="">
                  <a v-if="!this.$store.state.email" @click="$router.push('/account/'+$store.state.klubinfo.ime + '/login/')" style="color:white;background: #494B4E !important;"> NightWise Account <i class="fas fa-angle-right" style="color:#494B4E;background: white !important;"></i></a>
                  <a v-if="this.$store.state.email" @click="$router.push('/nightwise/user/')" style="color:white;background: #494B4E !important;cursor: pointer;">{{ this.$store.state.email }} <i class="fa fa-user" style="color:#494B4E;background: white !important;"></i></a>
               </div><br><br>


        <span
      v-for="(item, index) in vrsteproizvoda"
      :key="index"
      :data-type="item"
      :class="{ 'tagsort-active': index === 0 }"
      :id="'menu'+index"
      @click="filter(item, index)"
    >
      {{ item }}
    </span>

    </div>
    </div>
    </div>
    <input type="text" class="form-control" placeholder="Search" id="searchjelainput" style="border">
    
    <div class="row menu-items">

      
 
    <template  v-for="i in jela">
        <div :class="'menu-item JELO col-sm-6 col-xs-12' + ' ' + i[4].replace(/\s/g, '') + ' ' + i[3]" v-show="i[4] == this.vrsteproizvoda[0]">

           <div class="clearfix menu-wrapper" style="z-index: 0;">
               <!--<div class="menu-img rounded-circle " style="border-radius: 50%!important; padding-bottom: 3%;">
           <img class=" resize-image" :src="i[0]" onerror="this.style.display='none'" >        <i class="fa fa-plus-circle" id="dodaj" style="cursor: pointer;font-size:30px;color:var(--button-color);" @click="addToCart(i[4], i[2], i[6], i[3])"></i>&nbsp;&nbsp;
       </div>-->
     
                      
       <h4 class="testh4">
     

         
                                <i class="fa fa-plus-circle dodajgumb"  id="dodaj" style="cursor: pointer;font-size:30px;color:var(--footercolor);margin-bottom: 10px;user-select: none;" @click="addToCart(i[3], i[2], i[4], i[5])"></i>&nbsp;&nbsp;
    
       <span>{{i[3]}}</span>
   

       
       </h4>
       <span class="price">€{{i[2]}}   </span>
       <div class="dotted-bg"></div>
       </div>
       <p class="menup">{{i[1]}}</p>
       </div>
    </template>

  
    
    
    </div>
    </div>
    </div>
    </section>

    <div class=" fabs">
        <button class="btn btn-info fab" @click="$router.push('/cart/'+this.club+'/'+this.table)"  style="border:none;color:white;background-color:var(--footercolor) ; z-index:5;border-radius: 20px;"> 
            <a  class="btn-getstarted scrollto position-relative" id="podsjetnik" style="color:white;font-size: 20px;">
            <i class="fa fa-basket-shopping" style="font-size: 25px;"></i>
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light text-dark badgecircle" style="font-size: 13px;">
                {{ cartTotalLength }}
        <span class="visually-hidden"></span>
      </span>
    
    </a> &nbsp;</button>


    </div>
   
   


</template>
</Navigation>
  

</template>


<script>
import Navigation from '../components/Navigation.vue'
import axios from 'axios'
export default {
  name: 'Restaurant',
  components: {
    Navigation
  },

  data(){
    return{
        jela:[],
        table:0,
        club:"",
        vrsteproizvoda:[],
        jelo:{
            id:"",
            vrsta:"1",
        },
        cart: {
        items: [],
    
        
      },
      podvrste:[],
      bgstylevar:""
    }
  },

  beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.token

    console.log(this.club)

    if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
        axios.defaults.headers.common['Authorization'] = ""
    }
  },
  mounted(){
    console.log(this.$store.state)

    console.log("klub id u cartu je ", this.$store.state.cart.klubid)
    console.log("klub id je ", this.$store.state.klubid)
    if(!this.$store.state.cart.klubid || (this.$store.state.cart.klubid != this.$store.state.klubid) ){

      this.$store.commit('clearCart')
      this.$store.commit('setCartKlub', this.$store.state.klubid)

      
    }

  
    
    $(".containerloading").show()
    this.cart = this.$store.state.cart


    this.table = this.$route.params.stol
    this.club = this.$route.params.klub


    
  
    this.searchJela();


    this.getJela()
    
  

  },
  computed: {
      cartTotalLength() {
          let totalLength = 0
          console.log("cart items")
          console.log(this.cart.items)
          for (let i = 0; i < this.cart.items.length; i++) {
           
            
              totalLength += parseInt(this.cart.items[i].quantity)
          }
          console.log(totalLength)
          return totalLength;

      }
  },


  methods:{
filter(item, index){
    $('.menu-tags .tagsort-active').removeClass('tagsort-active');
    $('#menu'+index).addClass('tagsort-active');

    $(".menu-items .menu-item").each(function(){
            
            console.log(item)
            if(!$(this).hasClass(item.replace(/\s/g, ''))){
                $(this).hide()
            }
            else{
                $(this).show()
                
            }

        });

},

  addToCart(jelo, price, vrsta, id) {
        const item = {
            jelo: jelo,
            price: price,
            quantity: "1",
            vrsta:vrsta,
            id:id,
        }
        
        
        this.$store.commit('addToCart', item)
        var badge = document.querySelector('.badgecircle');
        badge.classList.add('pulse-animation');
        badge.addEventListener('animationend', function() {
        badge.classList.remove('pulse-animation');
    }, {once: true});

           
    },


  searchJela(){
    $("#searchjelainput").keyup(function(){
        var inputText = $("#searchjelainput").val().toLowerCase();
        
        if ((inputText.length) > 0) {            
        
            $('.JELO').each(function() {
      
                    var $this = $(this);
                  
                
                  if($this.attr('class').toLowerCase().match(inputText)) {
                    $this.show()
                  }
                  else{
                    $this.hide()
                  }

                  
            
      
        }); 
    }
    else{
        $(".JELO").show()
    }

  });

  },


     async getJela() {
        
      await axios
        .get('/klub/meni/'+this.$route.params.klub)
        .then(response => {
            console.log(response)
          this.jela = response.data.proizvodi
          var vrste = {}
          for (let i = 0; i < this.jela.length; i++) {
            if (!vrste.hasOwnProperty(this.jela[i][4])) {
                this.vrsteproizvoda.push(this.jela[i][4])
    vrste[this.jela[i][4]] = []
  } 
            

           }



        $(".containerloading").hide()
          

        
        })
        .catch(error => {
    
        })

    },


  },

}
</script>
<style scoped>

.pulse-animation {
    animation: pulse 0.3s;
    animation-iteration-count: 1;
}

@keyframes pulse {
    0% {
        font-size: 12px;
    }
    25% {
        font-size: 14px;
    }
    50% {
        font-size: 16px; /* Increase font size as desired */
    }
    75% {
        font-size: 14px;
    }
    100% {
        font-size: 12px;
    }
}


.fabs {
    bottom: 0px;
    position: fixed;
    margin: 1em;
    right: 0;
    z-index: 998;

}

.fab {
    display: block;

    height: 56px;
    border-radius: 50%;
    text-align: center;
    color: #f0f0f0;
    margin: 25px auto 0;
   
    box-shadow: 0 0 4px rgba(0, 0, 0, .14), 0 4px 8px rgba(0, 0, 0, .28);
    cursor: pointer;
    -webkit-transition: all .1s ease-out;
    transition: all .1s ease-out;
    position: relative;
    z-index: 998;
    overflow: hidden;
    background: #42a5f5;
}





.fab>i {
    font-size: 2em;
    line-height: 55px;
    -webkit-transition: all .2s ease-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.fab:not(:last-child) {
    width: 0;
    height: 0;
    margin: 20px auto 0;
    opacity: 0;
    visibility: hidden;
    line-height: 40px;
}

.fab:not(:last-child)>i {
    font-size: 1.4em;
    line-height: 40px;
}

.fab:not(:last-child).is-visible {
    width: 40px;
    height: 40px;
    margin: 15px auto 10;
    opacity: 1;
    visibility: visible;
}

.fab:nth-last-child(1) {
    -webkit-transition-delay: 25ms;
    transition-delay: 25ms;
}

.fab:not(:last-child):nth-last-child(2) {
    -webkit-transition-delay: 20ms;
    transition-delay: 20ms;
}

.fab:not(:last-child):nth-last-child(3) {
    -webkit-transition-delay: 40ms;
    transition-delay: 40ms;
}

.fab:not(:last-child):nth-last-child(4) {
    -webkit-transition-delay: 60ms;
    transition-delay: 60ms;
}

.fab:not(:last-child):nth-last-child(5) {
    -webkit-transition-delay: 80ms;
    transition-delay: 80ms;
}

.fab(:last-child):active,
.fab(:last-child):focus,
.fab(:last-child):hover {
    box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
} 


.dodajgumb:hover{   
    font-size: 10%;
    color:rebeccapurple;
}
@media screen and (min-width: 1000px) {
    .sub-banner-sec{
        padding-top: 80px;
        padding-bottom: 50px
    }
}

#dodaj:hover {
color: #28b9d4;
} 


.price{
    color:#858fa1!important;
}
@media (max-width: 767px){
h1, .h1 {
    font-size: 32px;
}

}

.imgjelo {
  width: 100%;
  height: 300px;
}

.img--coverjelo {
  object-fit: cover;
}

.img--containjelo {
  object-fit: contain;
}


.JELO {
  opacity: 1;
  transition: opacity 1s; 
}

.JELO.fade {
  opacity: 0;
}

.menup {
    
    color: #828281;
    font-size: 22px;
}


.testh4{
    color:#848b99 !important;
    font-size:120%;
    font-family: raleway, sans-serif;

    color: #4b4741;
    text-align: left;
    margin: 5px 5px;

    padding-right: 10px;
    display: inline;
    background: #fff;
    font-weight: 800
}

.small, small {
    font-size: 65%;
    font-family: josefin sans,sans-serif;
    font-weight: 700;
    display: block!important;
    text-transform: none;
    text-align: center;
    margin: 10px 0 15px;
}
.testh1{
    font-family: raleway, sans-serif;
    text-transform: uppercase;
    color: var(--color-primary);
    text-align: center;
    font-weight: 800
}

.menu {
    padding-bottom: 30px
}
.space60 {
    margin-bottom: 60px
}
.menu-tags {
    text-align: center;
    margin-bottom: 20px
}
.menu-tags2, .menu-tags3, .menu-tags4 {
    text-align: center;
    margin-bottom: 50px;
    margin-top: 20px
}
.menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
    font-family: raleway, sans-serif;
    font-size: 18px;
    color: #828281;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 20px;
    padding-bottom: 2px;
    padding-top: 5px;
    cursor: pointer;
    transition: all .5s ease;
    border-bottom: 3px solid transparent
}
.menu-tags .tagsort-active, .menu-tags2 .tagsort2-active, .menu-tags3 .tagsort3-active, .menu-tags4 .tagsort4-active {
    border-bottom: 3px solid var(--button-color);
    color: #4b4741;

}
.menu .food-menu .menu-item, .menu .food-menu .menu-item3 {
    overflow: hidden;
    margin: 15px 0;
    padding-left: 10px;
    border-left: 3px solid #fff
}
.menu .food-menu .menu-item.featured {
    border-left: 3px solid #f9c56a
}
.menu .food-menu .menu-item .dotted-bg, .menu .food-menu .menu-item3 .dotted-bg {
    border-top: dotted 2px #ccc;
    left: 0;
    top: 15px;
    width: 100%;
    z-index: -1
}
.menu .food-menu .menu-item .menu-wrapper, .menu .food-menu .menu-item3 .menu-wrapper {
    position: relative;
    z-index: 1
}
.menu .food-menu .menu-item h4 class="testh4", .menu .food-menu .menu-item3 h4 class="testh4" {
    text-align: left;
    margin: 5px 0;
    float: left;
    padding-right: 10px;
    display: inline;
    background: #fff
}
.menu .food-menu .menu-item p, .menu .food-menu .menu-item3 p {
    font-size: 18px
}
.menu .food-menu .menu-item .price, .menu .food-menu .menu-item3 .price {
    float: right;
    padding-left: 10px;
    line-height: 19.8px;
    margin: 5px 0;
    background: #fff none repeat scroll 0% 0%;
    font-size: 22px;
    font-weight: 700
}
.menu .food-menu .menu-item-active {
    border-left: 3px solid #f9c56a
}
.menu .food-menu .menu-btn {
    text-align: center;
    padding: 20px 0
}

.menu-more, .menu-more:hover {
    width: 55px;
    height: 55px;
    -webkit-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
    -moz-border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
    border-radius: 27px 28px 28px 27px/27px 27px 28px 28px;
    background-color: #f9c56a;
    -webkit-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
    -moz-box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
    box-shadow: 0 5px 4px rgba(177, 177, 177, .75);
    text-shadow: 0 5px 5px #c1913e;
    border: solid 1px #f2ba57;
    color: #fff;
    line-height: 0;
    font-weight: 300;
    font-size: 65px;
    text-align: center;
    display: table;
    position: absolute;
    z-index: 48;
    bottom: -27.5px;
    right: 45px;
    font-family: Raleway;
    padding-top: 27px;
    text-decoration: none
}
.menu2-overlay {
    background: rgba(19, 19, 19, .7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: .4s
}
.menu-info:hover .menu2-overlay {
    opacity: 1;
    visibility: visible;
    transition: .4s
}
.menu2-overlay h4 class="testh4" {
    font-size: 19px;
    color: #fff;
    margin: 46px 0 5px
}
.menu2-overlay p {
    color: #fff;
    font-size: 19px;
    line-height: 24px
}
.menu2-overlay .price {
    font-family: Josefin Sans;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    border-top: 1px solid #3e3d3b;
    width: 100%;
    display: table;
    margin: 17px 0 0;
    padding-top: 17px
}
.menu-item3 img {
    width: 83px;
    float: left
}
.menu-item3 .menu-wrapper {
    margin-left: 115px
}
.menu-items3 p {
    float: left
}
.menu-items4 {
    margin-bottom: 75px
}
.menu-item4 {
    margin-bottom: 30px
}
.menu-item4 a {
    text-decoration: none !important
}
.menu4-overlay {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 5px 5px #e2e2e2;
    margin-bottom: 0;
    margin-top: -10px;
    text-align: center;
    text-decoration: none !important;
    display: table
}
.menu4-overlay h4 class="testh4" {
    font-size: 19px;
    color: #4b4741;
    margin: 46px 0 15px
}
.menu4-overlay p {
    color: #4b4741;
    font-size: 18px;
    line-height: 24px
}
.menu4-overlay .price {
    font-family: Josefin Sans;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    color: #e7ad48;
    width: 100%;
    display: table;
    margin: 0;
    padding: 13px 0 45px
}

@media(max-width:992px) {
    .list {
        padding-left: 0;
        margin-bottom: 0
    }
    .navbar .navbar-nav>li>a {
        padding: 9px 15px
    }
    .subscribe .btn-default {
        padding: 0 15px;
        height: 46px;
        border: 1px solid #f2ba57;
        margin-left: 0 px;
        margin-top: 0
    }
    .team-staff {
        border-bottom: 1px solid #dcdddd;
        padding-bottom: 30px;
        margin-bottom: 30px
    }
    .contact-social {
        margin-bottom: 60px
    }
    .menu-item2 {
        margin-bottom: 50px;
        width: 50%
    }
    .menu .food-menu .menu-item3 h4 class="testh4" {
        font-size: 14px
    }
    .menu-item4 {
        width: 50%
    }
    .rc-info {
        margin-left: 0
    }
    .recipie-content img {
        float: none;
        width: 100%;
        margin-bottom: 25px
    }
    .recipie-content hr {
        margin: 20px 0 50px
    }
    .rc-info {
        margin-bottom: 30px
    }
}
@media(max-width:767px) {
    .reservation .reservation-form .reservation-btn a {
        padding: 15px 14px;
        font-size: 15px
    }
    .table-responsive {
        padding: 10px 19px
    }
    .fp-content img {
        width: 100%
    }
    .recipie-content hr {
        margin: 20px 0 50px
    }
    .rc-info {
        margin-bottom: 30px
    }
    .menu-item2 {
        width: 100%
    }
    .menu-item2 img, .menu-item4 img {
        min-width: 100%
    }
    .post-date {
        margin: 0 0 30px;
        float: none
    }
    article h4 class="testh4" {
        margin: 40px 0 10px
    }
    h1, .h1 {
        font-size: 32px
    }
    .subscribe .btn-default {
        paddwing: 0 15px;
        height: 46px;
        border: 1px solid #f2ba57;
        margin-left: 0 px;
        margin-top: 0
    }
    .subscribe input {
        width: auto !important
    }
    .trusted-quote .quote-body {
        font-size: 19px
    }
    .trusted-quote .quote-author {
        font-size: 18px
    }
    .menu-tags span, .menu-tags2 span, .menu-tags3 span, .menu-tags4 span {
        font-size: 15px;
        margin: 0 8px 4px;
        display: inline-block
    }
    .page_header h2 {
        font-size: 61px
    }
    .menu .food-menu .menu-item3 h4 class="testh4" {
        font-size: 14px
    }
    .menu-item4 {
        width: 100%
    }
}
@media(max-width:480px) {
    .reservation .reservation-form {
        padding: 10px
    }
    .special-slider .slider-content .btn {
        margin: 17px 5px 20px 0;
        font-weight: 700;
        font-size: 10px;
        letter-spacing: normal;
        padding: 10px 20px;
        float: left
    }
    .element-tab .nav-tabs>li>a {
        color: #4b4741;
        font-size: 12px;
        font-family: Raleway;
        padding: 19px 14px
    }
    .cart-table tbody tr td a {
        font-size: 11px
    }
    .cart-table {
        font-size: 8px
    }
    .cart-table img {
        max-width: 50px;
        height: auto
    }
    .menu .food-menu .menu-item3 .price {
        
        float: none;
        padding-left: 0;
        margin: 6px 0 11px;
        font-size: 22px;
        display: table;
        padding-top: 10px
    }
    .contact-info p {
        font-size: 15px
    }
    .menu2-overlay h4 class="testh4" {
        font-size: 17px;
        margin: 22px 0 5px
    }
    .table>tbody>tr>td {
        padding: 8px 2px
    }
    .shop-grid select {
        float: none;
        display: block;
        margin-bottom: 15px
    }
    .sg-list {
        margin-left: -12px
    }
}

.menu-info {
    position: relative;
    overflow: hidden
}
.menu-item4 img {
    transform: scale(1);
    transition: .4s;
    opacity: 1
}
.menu-item4:hover img {
    transform: scale(1.1);
    transition: .4s;
    opacity: .9
}
.menu4-overlay {
    position: relative;
    z-index: 444
}
.menu-item2 img {
    transform: scale(1);
    transition: .4s;
    opacity: 1
}
.menu-item2:hover img {
    transform: scale(1.1);
    transition: .4s;
    opacity: .9
}


</style>

