import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Details from '../views/Details.vue'
import About from '../views/About.vue'
import Menu from '../views/Menu.vue'
import Cart from '../views/Cart.vue'
import Uspjeh from '../views/Uspjeh.vue'
import MusicOrder from '../views/MusicOrder.vue'
import Register from '../views/Register.vue'
import Bodovi from '../views/Bodovi.vue'
import Account from '../views/Account.vue'
import Search from '../views/Search.vue'
import NotFound from '../views/NotFound.vue'
import Checkout from '../views/Checkout.vue'
import CheckoutReturn from '../views/CheckoutReturn/Ticket.vue'
import OrderReturn from '../views/CheckoutReturn/Order.vue'
import store from '../store'

const routes = [
  {
    path: '/nightwise/user',
    name: 'profile',
    component: Account,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/stripe/checkout',
    name: 'checkout',
    component: Checkout
  },
  {
    path: '/checkout/order/return/',
    name: 'successorder',
    component: OrderReturn
  },
  {
    path: '/checkout/ticket/return/',
    name: 'success',
    component: CheckoutReturn
  },

  {
    path: '/:klub/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'Search',
    component: Search
  },
  {
    path: '/404/klub/',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/loyalty/:klub',
    name: 'loyalty',
    component: Bodovi,
    meta: {
      requireLogin: true
    }
   
  },

  {
    path: '/account/:klub/:type',
    name: 'register',
    component: Register
  },

  {
    path: '/details/:klub/:id',
    name: 'details',
    component: Details
  },
  {
    path: '/menu/:klub/:stol',
    name: 'menu',
    component: Menu
  },
  {
    path: '/about/:klub/',
    name: 'aboutfantasea',
    component: About
  },
  {
    path: '/cart/:klub/:stol/',
    name: 'cart',
    component: Cart
  },
  {
    path: '/uspjeh/:klub/:stol/',
    name: 'uspjeh',
    component: Uspjeh
  },
  {
    path: '/musicorder/:klub/:stol/',
    name: 'music',
    component: MusicOrder,
    meta: {
      requireLogin: true
    }
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the navigation is to the top or saved position is available, use the saved position
    if (savedPosition || to.hash) {
      return savedPosition || { left: 0, top: 0 };
    }
    // Otherwise, scroll to the top
    return { left: 0, top: 0 };
  },
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    if(store.state.klubinfo.ime){
      return next('/account/' + store.state.klubinfo.ime + '/login/')
    }
    else{
      return next('/account/nightwise/login/')
    }
    
  } else {
    next()
  }
})


export default router
